import '../coupon/redeem-coupon/redeem-coupon.scss';
import Button from './Button';

interface FailModalProps {
  isOpen: boolean;
  onClose: () => void;
  message: string;
}

const FailModal = ({ isOpen, onClose, message }: FailModalProps) => {
  return (
    <>
      {isOpen && (
        <div className="modal-container fixed inset-0 z-50">
          <div
            className="flex flex-col w-full justify-center items-center relative"
            style={{ top: '21.5%' }}
          >
            <div className="w-5/6">
              <Button
                className="icon-close-modal h-14 w-1/6"
                onClick={onClose}
              />
            </div>
            <div className="mt-2 w-5/6 redeem-modal-border">
              <div className="text-2xl font-bold text-white text-center redeem-modal-content">
                <div className="flex h-full">
                  <div className="m-auto">
                    <h1>{message}</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FailModal;
