import Button from '@components/Button';
import { getExamPointList } from '@core/@services/exam.service';
import { PointResponse, SubmitPointHistory } from 'exam/exam.model';
import React, { useEffect, useState } from 'react';
import StarIcon from '../../assets/layout/exam-info/star.svg';
import TimeIcon from '../../assets/layout/exam/time.svg';
import './point-history.scss';

const ExamPointHistory = ({ isOpen, onClose }) => {
  const [data, setData] = useState<PointResponse>();

  useEffect(() => {
    const req: SubmitPointHistory = {
      page_index: 1,
      page_size: 0,
    };
    getExamPointList(req).then((res) => {
      setData(res);
    });
  }, []);

  const getReceivePoint = (point: number): string => {
    return point === 0 ? `${point}` : point > 0 ? `+${point}` : `${point}`;
  };

  return (
    <>
      {isOpen && (
        <div className="exam-point-modal-container justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none fade">
          <div className="relative w-10/12 my-6 mx-auto max-w-3xl">
            <Button
              className="exam-point-modal-close h-14 w-1/6"
              onClick={onClose}
            ></Button>
            <div className="border-0 rounded-lg relative flex flex-col w-full outline-none focus:outline-none">
              <div className="exam-point-modal-header">
                <label className="exam-point-text-history">ประวัติคะแนน</label>
              </div>
              <div className="overflow-scroll exam-point-modal-content border-0 rounded-lg shadow-lg relative flex-auto text-center">
                {data?.point_list?.map((d) => (
                  <div
                    key={d.exam_id}
                    className="exam-point-modal-box"
                    style={
                      d.point_type === 3
                        ? { background: '#d9eef5', border: '2px solid red' }
                        : {}
                    }
                  >
                    <div className="flex items-center p-3">
                      <div className="exam-title-content text-left">
                        <label className="block exam-title whitespace-pre-wrap">
                          {d.exam_title}
                        </label>
                        <div className="flex items-center">
                          <label className="exam-date">{d.exam_date}</label>
                          {d.point_type !== 3 && (
                            <>
                              <div className="flex ml-2">
                                <img className="image-style" src={TimeIcon} />
                                <label className="ml-2 exam-time">
                                  {d.exam_time}
                                </label>
                              </div>
                              <div className="flex">
                                <img src={StarIcon} className="image-style" />
                                <label className="exam-point ml-2">
                                  {d.exam_time}
                                </label>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="exam-receive-point-content text-center">
                        <label className="exam-receive-point">
                          {getReceivePoint(d.receive_point)}
                        </label>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ExamPointHistory;
