import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppState } from '@contexts/AppContext';

import Sound from 'react-sound';
import examSound from 'assets/sound/mixkit-delightful-4.mp3';
import sound from 'assets/sound/sound-bg-exam.mp3';

interface SoundType {
  sound: { start: () => void; stop: () => void };
  props: { playStatus: string };
}

const bgSound = new Audio(sound);
bgSound.volume = 0.1;

const BGSound = (): React.ReactElement | null => {
  const { enableSound, enableBgSound, enableExamBgSound, pageActive } =
    useAppState();
  const [error, setError] = useState<any>();
  const dispatch = useAppDispatch();
  const ref = useRef<SoundType>(null);

  const windowClick = () => {
    if (ref.current) {
      ref.current.sound.start();
    }
    if (error !== undefined) {
      setError(undefined);
    }
    checkPermission();
  };

  const checkPermission = () => {
    bgSound
      .play()
      .then(() => {
        bgSound.pause();
        window.removeEventListener('click', windowClick, false);
      })
      .catch((e) => {
        bgSound.pause();
        setError(e.message);
      });
  };

  useEffect(() => {
    window.addEventListener('click', windowClick, false);
    window.onload = () => {
      dispatch({ type: 'SOUND', payload: true });
      checkPermission();
    };
  }, []);

  const bgStatus = useMemo(() => {
    if (enableSound && enableBgSound && !enableExamBgSound) {
      if (pageActive) {
        return Sound.status.PLAYING;
      } else {
        return Sound.status.PAUSED;
      }
    }
    return Sound.status.STOPPED;
  }, [enableSound, enableBgSound, enableExamBgSound, pageActive]);

  const examBgStatus = useMemo(() => {
    if (enableSound && enableExamBgSound && pageActive) {
      if (pageActive) {
        return Sound.status.PLAYING;
      } else {
        return Sound.status.PAUSED;
      }
    }
    return Sound.status.STOPPED;
  }, [enableSound, enableExamBgSound, pageActive]);

  return (
    <>
      <Sound
        ref={ref}
        url={sound}
        volume={30}
        playStatus={bgStatus}
        autoLoad={true}
        loop={true}
        ignoreMobileRestrictions={true}
      />
      <Sound
        url={examSound}
        volume={30}
        playStatus={examBgStatus}
        autoLoad={true}
        loop={true}
        ignoreMobileRestrictions={true}
        onError={console.log}
      />
    </>
  );
};

export default BGSound;
