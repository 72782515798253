interface ENV {
  apiURL?: string;
  registerLiffId?: string;
  loginLiffId?: string;
  pointLiffId?: string;
  couponLiffId?:string;
}

export const env: ENV = {
  apiURL: process.env.REACT_APP_API_URL,
  registerLiffId: process.env.REACT_APP_LIFF_REGISTER,
  loginLiffId: process.env.REACT_APP_LIFF_LOGIN,
  pointLiffId: process.env.REACT_APP_LIFF_POINT,
  couponLiffId: process.env.REACT_APP_LIFF_COUPON,
};
