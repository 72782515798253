import React, { InputHTMLAttributes } from 'react';

import './Input.scss'

const Input = React.forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement>
>((props, ref) => {
  return (
    <input
      ref={ref}
      className="input appearance-none font-input-size-16 w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
      {...props}
    />
  );
});

export default Input;
