import './info-graphic.scss';

import React, { useContext } from 'react';

import { ExamContext } from '@contexts/ExamContext';

const InfoGraphic = () => {
  const {
    examInfo: { exam_infographic_image },
  } = useContext(ExamContext);

  return (
    <div className="info-graphic pb-2 absolute flex flex-col items-center">
      <div className="header-label w-3/6 border-r-4 border-l-4 p-8"></div>
      <div className="header-label w-3/5 border-4"></div>
      <div className="flex flex-col items-center px-6 pb-8">
        <img src={exam_infographic_image} />
      </div>
    </div>
  );
};

export default InfoGraphic;
