import axios from "@core/axios";
import { PrefixRequest } from "member/member.model";

const GetShopName = (data: PrefixRequest) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`/Member/reqShops`, data)
            .then((res: any) => {
                if (res.status === 200) {
                    return resolve(res);
                }
            }).catch((err: any) => reject(err.response));
    });
};

export default GetShopName;