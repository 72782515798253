import * as React from 'react';

import { Information } from '@components/Information';
import LeftBackItem from '@components/LeftBackItem';
import { useHistory } from 'react-router-dom';
import { ImageProfile } from '@components/ImageProfile';

export const MenuDetailLayout: React.FC = ({ children }) => {
  const history = useHistory();
  return (
    <div className="shiba flex">
      <div className="header z-20">
        <div className="absolute w-full px-4 pt-3">
          <LeftBackItem onClick={() => history.push('/menu')} />
          <Information />
        </div>
        <div className="absolute top-14 right-3">
          <div className="image-profile">
            <ImageProfile />
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};
