import React, { useEffect } from 'react';

import { useAppDispatch } from './AppContext';
import { useLocation } from 'react-router-dom';

const PageProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const appDispatch = useAppDispatch();

  useEffect(() => {
    if (location.pathname === '/exam') {
      appDispatch({ type: 'BG_SOUND', payload: false });
      appDispatch({ type: 'EXAM_BG_SOUND', payload: true });
    } else {
      appDispatch({ type: 'BG_SOUND', payload: true });
      appDispatch({ type: 'EXAM_BG_SOUND', payload: false });
    }
    return () => {
      appDispatch({ type: 'BG_SOUND', payload: false });
      appDispatch({ type: 'EXAM_BG_SOUND', payload: false });
    };
  }, [location]);

  return <>{children}</>;
};

export { PageProvider };
