import { ExamContext } from '@contexts/ExamContext';
import { getFormInfo } from '@core/@services/exam.service';
import {
  FormInfo,
  FormQuestionList,
  FormQuestionListDetail,
  QuestionValueList,
} from 'exam/exam.model';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './exam.scss';
import useHeight from '@hooks/useHeight';

function getAnswerList(value: string): QuestionValueList[] {
  return JSON.parse(value);
}

interface LocationState {
  id: string;
}

export const Form = () => {
  const {
    examNo,
    submitScore,
    formInfo: { form_question_list, form_id },
    setFormAnswerList,
    formAnswerList,
    setFormInfo,
    setExamNo,
  } = useContext(ExamContext);

  const height = useHeight(100);

  const form_question_id =
    form_question_list[examNo - 1].form_question_id || '';
  const locationState = useLocation<LocationState>().state;

  const [answer, setAnswer] = useState<string[]>([]);

  const firstUpdate = useRef(true);

  useEffect(() => {
    if (locationState?.id) {
      getFormInfo(locationState.id).then((data: FormInfo) => {
        setExamNo(1);
        setFormInfo(data);
      });
    }
  }, [locationState?.id]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    setAnswer(
      formAnswerList.question_value_list
        .filter((q) => q.form_question_id === form_question_id)
        .map((v) => v.value)
    );
  }, [examNo]);

  const onAnswerChange = (answer: string[]) => {
    const answerlist = getAnswerList(
      form_question_list[examNo - 1].form_question_detail_default
    );

    const newanswer: QuestionValueList[] = answer.map((value) => {
      return {
        form_question_id,
        name: answerlist.find((v) => v.value === value)?.name || '',
        value,
      };
    });

    const question_value_list = formAnswerList.question_value_list.filter(
      (value) => value.form_question_id !== form_question_id
    );

    question_value_list.push(...newanswer);

    setFormAnswerList({
      form_id,
      question_value_list: question_value_list,
    });
  };

  const CheckboxList = (formQuestionList: FormQuestionList) => {
    const answers = getAnswerList(
      formQuestionList.form_question_detail_default
    );

    return answers.map((answerlist: FormQuestionListDetail, index: number) => (
      <label
        className="inline-flex mt-2 w-full"
        key={`${form_question_id}-${answerlist.name}-${index}`}
      >
        <input
          type="checkbox"
          className="form-radio exam-radio h-7 w-7"
          name={form_question_id}
          value={answerlist.value}
          onChange={(e) => {
            if (answer.includes(answerlist.value)) {
              const removed = answer.filter((value) => value !== e.target.value)
              setAnswer([...removed]);
              onAnswerChange([...removed]);
            } else {
              setAnswer([...answer, e.target.value]);
              onAnswerChange([...answer, e.target.value]);
            }
          }}
          checked={answer.includes(answerlist.value)}
        />
        <span className="ml-2 text-2xl whitespace-pre-wrap">
          {answerlist.name}
        </span>
      </label>
    ));
  };

  const RadioList = (formQuestionList: FormQuestionList) => {
    const answers = getAnswerList(
      formQuestionList.form_question_detail_default
    );

    return answers.map((answerlist: FormQuestionListDetail, index: number) => (
      <label
        className="inline-flex mt-2 w-full"
        key={`${form_question_id}-${answerlist.name}-${index}`}
      >
        <input
          type="radio"
          className="form-radio exam-radio h-7 w-7"
          name={form_question_id}
          value={answerlist.value}
          onChange={(e) => {
            setAnswer([e.target.value]);
            onAnswerChange([e.target.value]);
          }}
          checked={answer.includes(answerlist.value)}
        />
        <span className="ml-2 text-2xl whitespace-pre-wrap">
          {answerlist.name}
        </span>
      </label>
    ));
  };

  return (
    <div className="fixed top-40 px-8 w-full overflow-y-auto">
      <div className={`exam-border + ${submitScore ? 'complete' : ''}`}>
        <div
          className={`exam + ${
            submitScore ? 'complete' : ''
          } h-full text-2xl p-2`}
        >
          <p className="header-question text-3xl font-bold text-center whitespace-pre-wrap">
            {examNo}.
            {form_question_list &&
              form_question_list[examNo - 1].form_question_title}
          </p>
          <div
            className="overflow-y-auto"
            style={{ maxHeight: `calc(${height} - 375px)` }}
          >
            {form_question_list &&
              form_question_list[examNo - 1].form_question_detail_default &&
              (form_question_list[examNo - 1].input_type_id === 7
                ? RadioList(form_question_list[examNo - 1])
                : CheckboxList(form_question_list[examNo - 1]))}
          </div>
        </div>
      </div>
    </div>
  );
};
