import React from 'react';

const RopeAndHeader: React.FC = ({ children }) => {
  return (
    <div className="flex flex-col items-center">
      <div className="rope w-1/2 p-10 lg:p-40 md:p-32 sm:p-20"></div>
      <div className="btn-menu-item text-3xl text-center z-30 lg:text-7xl tracking-wider rounded-2xl lg:rounded-3xl whitespace-pre-wrap">
        {children}
      </div>
    </div>
  );
};

export default RopeAndHeader;
