import * as React from 'react';

import { ExamButtonBG, ExamButtonType } from './ExamButton';
import { useEffect, useState } from 'react';

import Button from './Button';
import ExamGotPoint from './ExamGotPoint';
import { useHistory } from 'react-router-dom';
import { Token } from '@core/core.model';

export interface SuccessFulModalProps {
  desc: string;
  point: number;
}

export const ExamButtonStyle = {
  height: '55px',
  width: '185px',
};

export const SuccessFulModal: React.FC<SuccessFulModalProps> = ({
  desc,
  point,
  children,
}) => {
  const history = useHistory();
  const [isRegister, setIsRegister] = useState<boolean>(true);

  const goTo = (page: string) => {
    const storageToken = sessionStorage.getItem('hillToken');
    if (storageToken) {
      const token: Token = {};
      Object.assign(token, JSON.parse(storageToken));
      if (token.access_token) {
        history.push('/menu');
        return;
      }
    }
    history.push('/login', { page });
  };

  useEffect(() => {
    const locationName = history.location.pathname;
    setIsRegister(locationName === '/register' || locationName === '/');
  }, [history.location.pathname]);

  return (
    <div className="modal-container flex flex-col justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none fade">
      <ExamGotPoint point={point}>{desc}</ExamGotPoint>
      {children ? (
        children
      ) : (
        <div className="flex">
          <Button
            style={{
              ...ExamButtonStyle,
              ...ExamButtonBG(ExamButtonType.ToClass),
            }}
            className="button pl-10"
            onClick={() => goTo('/menu')}
          >
            กลับสู่ห้องเรียน
          </Button>
          <Button
            style={{
              ...ExamButtonStyle,
              ...ExamButtonBG(ExamButtonType.ToRanking),
            }}
            className="button pl-10"
            onClick={() =>
              history.push(
                history.location.pathname === '/success-register-modal'
                  ? '/point'
                  : '/member-info'
              )
            }
          >
            {isRegister ? 'ดูคะแนนของฉัน' : ' ดูลำดับของฉัน'}
          </Button>
        </div>
      )}
    </div>
  );
};
