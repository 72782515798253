import axios from '@core/axios';
import { AxiosResponse } from 'axios';
import { MenuListResponse } from '../menu.model';

const GetMenuList = (): Promise<MenuListResponse> => {
  return new Promise((resolve, reject) => {
    axios
      .post<MenuListResponse>(`/Member/reqMenuList`, {})
      .then((res: AxiosResponse<MenuListResponse>) => {
        if (res.status === 200) {
          return resolve(res.data);
        }
      })
      .catch((err: any) => reject(err.response));
  });
};

export default GetMenuList;
