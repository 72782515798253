import './redeem-coupon.scss';

import React from 'react';
import RedeemCouponFooter from './RedeemCouponFooter';

const RedeemCoupon = ({ redeemPoint, isOpen, onClose, onSubmit, id }: any) => {
  const btnCloseClass =
    'btn-close z-30 text-3xl lg:text-7xl tracking-wider rounded-2xl lg:rounded-3xl';

  const btnSubmitClass =
    'btn-submit z-30 text-3xl lg:text-7xl tracking-wider rounded-2xl lg:rounded-3xl';

  const pointWithComma = () => {
    return redeemPoint
      ? redeemPoint.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      : '-';
  };

  const onSubmitRedeemCoupon = () => {
    onSubmit(id);
  };

  return (
    <>
      {isOpen && (
        <div className="modal-container flex flex-col justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none fade">
          <div className="pt-2 w-full flex flex-col justify-center items-center">
            <div className="whitespace-pre-wrap w-5/6 mt-2 redeem-modal-border">
              <div className="text-4xl font-bold text-white text-center redeem-modal-content">
                <div className="flex h-full">
                  <div className="m-auto">
                    <h1>ใช้ {pointWithComma()} คะแนน เพื่อแลกคูปอง</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex w-full justify-center mt-10">
            <RedeemCouponFooter
              onClose={onClose}
              onSubmit={onSubmitRedeemCoupon}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default RedeemCoupon;
