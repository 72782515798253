import BackButton, { BackButtonProps } from './BackButton';

import React from 'react';
import SoundIcon from './Sound/SoundIcon';
import styled from 'styled-components';

interface LeftBackItemProps extends BackButtonProps {
  showSound?: boolean;
}

const LeftBackItem: React.FC<LeftBackItemProps> = ({ showSound, ...props }: LeftBackItemProps) => {
  return <LeftWrapper>
    <BackButton {...props} /> 
    {showSound && <SoundIcon />}
  </LeftWrapper>
}

const LeftWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

LeftBackItem.defaultProps = {
  showSound: true
}

export default LeftBackItem;