import { MenuList, MenuListResponse } from './menu.model';
import { useEffect, useState } from 'react';

import GetMenuList from './api/GetMenuList';
import React from 'react';
import { useHistory } from 'react-router-dom';
import './menu.scss';

const MenuPage: React.FC = () => {
  const [menuList, setMenuList] = useState<MenuList[]>([]);
  const history = useHistory();

  useEffect(() => {
    if (sessionStorage.getItem('keep_data_flag') !== 'true') {
      history.push('/terms-and-conditions', {
        source: 'menu',
      });
      return;
    }

    GetMenuList()
      .then((res: MenuListResponse) => {
        setMenuList([...res.menu_list]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const onClick = (id: number) => {
    if (sessionStorage.getItem('keep_data_flag') !== 'true') {
      history.push('/terms-and-conditions');
      return;
    }

    if (id === 4) {
      history.push(`/document`);
      return;
    }
    history.push(`/menu-detail/${id}`);
  };

  return (
    <div
      className="grid grid-cols-2 gap-y-10 justify-items-center relative"
      style={{ top: '20%' }}
    >
      {menuList.map((menu: MenuList) => (
        <div
          className={`menu menu-${menu.menu_id}`}
          key={menu.menu_id}
          onClick={() => onClick(menu.menu_id)}
        ></div>
      ))}
    </div>
  );
};

export default MenuPage;
