import React from 'react';
import sound from 'assets/sound/mixkit-cool-interface-click-tone-2568.wav';
import { useAppState } from '@contexts/AppContext';

const clickSound = new Audio(sound);
clickSound.volume = 0.5;

interface ButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  disableSound?: boolean;
}

const Button: React.FC<ButtonProps> = ({ children, onClick, disableSound, ...props }) => {
  const app = useAppState();
  const onHandleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (app.enableSound && disableSound != true) {
      clickSound.play();
    }
    onClick && onClick(event);
  }
  return <button type="button" onClick={onHandleClick} {...props}>{children}</button>
}

export default Button;