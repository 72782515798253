import * as React from 'react';

export const RegisterLayout: React.FC = ({ children }) => {
  return (
    <div className="shiba fixed inset-0">
      <div className="header z-50">
        <div className="absolute h-full w-full px-6 flex items-center justify-between"></div>
      </div>
      <div className="md:box-content">{children}</div>
    </div>
  );
};
