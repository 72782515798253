import React, { useContext } from 'react';

import Button from './Button';
import CSS from 'csstype';
import Class from 'assets/layout/exam/bt_blue.svg';
import { ExamContext } from '@contexts/ExamContext';
import Ranking from 'assets/layout/exam/bt_red.svg';
import Star from 'assets/layout/exam-info/star.svg';
import { useHistory } from 'react-router-dom';

export const ExamButtonBG = (type: ExamButtonType): CSS.Properties => {
  const getBG = (type: ExamButtonType) => {
    return type === ExamButtonType.ToClass ? Class : Ranking;
  };
  return {
    background: 'url(' + getBG(type) + ') no-repeat center',
  };
};

const ExamButtonStyle: CSS.Properties = {
  height: '55px',
  width: '185px',
};

export enum ExamButtonType {
  ToClass,
  ToRanking,
}

type ExamButtonProps = {
  children: React.ReactNode;
  type: ExamButtonType;
};

const ExamButton = ({ children, type }: ExamButtonProps) => {
  const history = useHistory();
  const { setSubmitScore } = useContext(ExamContext);

  const onClick = (type) => {
    setSubmitScore(false);
    if (type === ExamButtonType.ToClass) {
      history.push('/menu');
      return;
    } else {
      history.push('/member-info');
    }
  };

  return (
    <Button
      style={{ ...ExamButtonStyle, ...ExamButtonBG(type) }}
      className="button pl-10"
      onClick={() => onClick(type)}
    >
      {children}
    </Button>
  );
};
export default ExamButton;
