import axios from '@core/axios';
import { AxiosResponse } from 'axios';
import {
  CheckAlertRedeemResponse,
  Coupon,
  CouponList,
} from 'coupon/coupon.model';

export const getCouponList = (): Promise<CouponList> => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/Member/reqCouponList`)
      .then((res: any) => {
        if (res.status === 200) {
          return resolve(res.data);
        }
      })
      .catch((err: any) => reject(err.response));
  });
};

export const getCouponInfo = (coupon_id: string): Promise<Coupon> => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/Member/reqCouponInfo`, { params: { coupon_id } })
      .then((res: any) => {
        if (res.status === 200) {
          return resolve(res.data);
        }
      })
      .catch((err: any) => reject(err.response));
  });
};

export const checkAlertRedeem = (): Promise<CheckAlertRedeemResponse> => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/Member/checkAlertRedeem`)
      .then((res: AxiosResponse<CheckAlertRedeemResponse>) => {
        if (res.status === 200) {
          return resolve(res.data);
        }
      })
      .catch((err: any) => reject(err.response));
  });
};
