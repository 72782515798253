import axios from '@core/axios';
import { DocumentDetail } from '@document/document.model';
import { AxiosResponse } from 'axios';


const GetDocument = (): Promise<DocumentDetail> => {
  return new Promise((resolve, reject) => {
    axios
      .post<DocumentDetail>(`/Member/reqDocumentList`, {})
      .then((res: AxiosResponse<DocumentDetail>) => {
        if (res.status === 200) {
          return resolve(res.data);
        }
      })
      .catch((err: any) => reject(err.response));
  });
};

export default GetDocument;
