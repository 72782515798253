import './point.scss';

import React, { useContext, useEffect, useState } from 'react';
import { ExamInfo, SubmitExam, SubmitExamResponse } from 'exam/exam.model';

import Button from '@components/Button';
import { ExamContext } from '@contexts/ExamContext';
import RefreshIcon from '../../assets/layout/exam/refresh.svg';
import StartScore from '@components/StarScore';
import TimeIcon from '../../assets/layout/exam/time.svg';
import { getExamInfo, submitExam } from '@core/@services/exam.service';
import { useHistory } from 'react-router-dom';
import { getDateTimeServer } from '@core/@services/date-time.service';

const ExamPoint = (props: { isOpen?: boolean; onClose?: any }) => {
  const { isOpen, onClose } = props;
  const history = useHistory();
  const {
    examInfo: { exam_id },
    answerList,
    startDateTime,
    endDateTime,
    setSubmitScore,
    setExamNo,
    setStartDateTime,
    setAnswerList,
    setExamInfo,
  } = useContext(ExamContext);
  const [fullPoint, setFullPiont] = useState(0);
  const [recievePoint, setRecievePoint] = useState(0);
  const [spendTime, setSpendTimet] = useState('');

  useEffect(() => {
    if (isOpen) {
      const request: SubmitExam = {
        submit_flag: false,
        exam_id,
        start_datetime: startDateTime,
        end_datetime: endDateTime,
        answer_list: answerList,
      };
      submitExam(request).then((data: SubmitExamResponse) => {
        setFullPiont(data.full_point);
        setRecievePoint(data.receive_point);
        setSpendTimet(data.spend_time);
      });
    }
  }, [isOpen]);

  const btnSendResultClass =
    'btn-send-result z-30 text-3xl lg:text-7xl tracking-wider rounded-2xl lg:rounded-3xl';
  const btnRefreshClass =
    'btn-refresh flex items-center justify-center lg:rounded-3xl lg:text-7xl rounded-2xl text-3xl tracking-wider z-30';

  const onSendResult = () => {
    setSubmitScore(true);
    onClose();
  };

  const onReExam = () => {
    getExamInfo(exam_id).then((data: ExamInfo) => {
      setExamInfo(data);
      getDateTimeServer().then((data: any) => {
        setStartDateTime(data.datetime);
        setExamNo(1);
        setAnswerList([])
        history.push('/exam');
        onClose();
      });
    });
  };

  return (
    <>
      {isOpen ? (
        <div className="modal-container justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none fade">
          <div className="relative w-10/12 my-6 mx-auto max-w-3xl">
            <div className="border-0 rounded-lg relative flex flex-col w-full outline-none focus:outline-none">
              <div className="modal-header"></div>
              <div className="border-0 rounded-lg modal-content shadow-lg bg-white relative flex-auto text-center">
                <label className="text-description">คะแนนของคุณ</label>
                <div className="flex justify-center p-3">
                  <StartScore
                    children={`${recievePoint}/${fullPoint}`}
                    color={'#265EA8'}
                  />
                </div>
                <div className="time-result justify-center items-center">
                  <div className="mb-3">
                    <img src={TimeIcon} />
                  </div>
                  <div className="time-text pl-6 pb-6">{spendTime}</div>
                </div>
                <div className="pb-3">
                  <Button
                    type="button"
                    className={btnSendResultClass}
                    onClick={onSendResult}
                  >
                    ส่งคะแนน
                  </Button>
                </div>
                <div className="pb-3">
                  <label className="text-decision">หรือ</label>
                </div>
                <div className="flex justify-center pb-10">
                  <Button
                    type="button"
                    className={btnRefreshClass}
                    onClick={onReExam}
                  >
                    <img className="mr-4" src={RefreshIcon} /> ทำอีกครั้ง
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ExamPoint;
