import '../member-info/member-info.scss';

import React, { useEffect, useState } from 'react';

import Button from '@components/Button';
import ExamPointHistory from 'exam/point-history/PointHistory';
import ExamRanking from 'exam/ranking/Ranking';
import GetMemberInfo from 'member/api/GetMemberInfo';
import { MemberInfoResponse } from 'member/member.model';
import StarIcon from '../../assets/layout/exam-info/star.svg';
import TimeIcon from '../../assets/layout/exam/time.svg';
import EditIcon from '../../assets/layout/profile/edit.svg';
import { checkAlertRedeem } from '@core/@services/coupon.service';
import { SuccessFulModal } from '@components/SuccessfulModal';
import { CheckAlertRedeemResponse } from 'coupon/coupon.model';
import RedeemCouponFooter from 'coupon/redeem-coupon/RedeemCouponFooter';
import { Link, useHistory, useLocation } from 'react-router-dom';

export interface LocationState {
  isCheckRedeem: boolean;
}
const MemberInfo = () => {
  const [memberInfo, setMemberInfo] = useState<MemberInfoResponse>();
  const [isOpenExamPointHistory, setIsOpenExamPointHistory] = useState(false);
  const [isOpenExamRanking, setIsOpenExamRanking] = useState(false);
  const [redeemDesc, setRedeemDesc] = useState<string>('');
  const onCloseExamPointHistory = () => setIsOpenExamPointHistory(false);
  const onCloseExamRanking = () => setIsOpenExamRanking(false);
  const history = useHistory();
  const locationState = useLocation<LocationState>();

  useEffect(() => {
    GetMemberInfo()
      .then((res: any) => {
        if (!res?.data?.otp_pass_flag) {
          const profile = JSON.parse(
            sessionStorage.getItem('liff-profile') || '{}'
          );

          history.push('/member-info-edit', {
            line_id: profile.userId,
            source: '/otp',
          });

          return;
        }

        setMemberInfo(res.data);
      })
      .catch((error) => {
        console.log(error, 'Get member info failer');
      });
  }, []);

  useEffect(() => {
    if (locationState.search) {
      checkAlertRedeem().then((res: CheckAlertRedeemResponse) => {
        setRedeemDesc(res.campaign_info[0].campaign_desc);
      });
    }
  }, [locationState.search]);

  const onClose = () => {
    setRedeemDesc('');
  };

  return (
    <>
      <div className="grid grid-cols-2 gap-4">
        <div className="flex items-center justify-center">
          <img className="h-5" src={StarIcon}></img>
          <label className="label-header-member ml-2 tracking-widest">
            {memberInfo?.point}
          </label>
        </div>
        <div className="flex items-center justify-center">
          <img className="h-6" src={TimeIcon}></img>
          <label className="label-header-member ml-2 tracking-widest">
            {memberInfo?.exam_time_total
              ? memberInfo?.exam_time_total
              : '00:00'}
          </label>
        </div>
        <div className="flex col-span-2 place-content-around">
          <Button
            className="label-header-member border-solid border pt-1 pb-1 pl-3.5 pr-3.5 rounded-3xl w-30"
            onClick={() => setIsOpenExamRanking(true)}
          >
            ดูลำดับคะแนน
          </Button>

          <Button
            className="label-header-member border-solid border pt-1 pb-1 pl-3.5 pr-3.5 rounded-3xl w-30"
            onClick={() => setIsOpenExamPointHistory(true)}
          >
            ประวัติคะแนน
          </Button>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 mt-8 m-auto w-10/12 rounded-3xl shadow-panel">
        <div className="grid gap-y-2 info-container">
          <Link className="edit-icon-link" to="member-info-edit">
            <img className="edit-icon" src={EditIcon}></img>
          </Link>
          <label className="label-member float-left mt-5 ml-6">
            ชื่อ-นามสกุล:
          </label>
          <label className="label-member float-left ml-6">
            {memberInfo?.full_name}
          </label>
        </div>
        <div className="grid gap-y-2 mb-8">
          <label className="label-member float-left mt-5 ml-5">
            {memberInfo?.member_type_id == 3
              ? 'ชื่อมหาวิทยาลัย'
              : 'จังหวัดที่ทำงาน'}
          </label>
          <label className="label-member float-left ml-5">
            {memberInfo?.member_type_id == 3
              ? memberInfo?.university_name
              : memberInfo?.province_name}
          </label>
        </div>
      </div>
      <ExamPointHistory
        isOpen={isOpenExamPointHistory}
        onClose={onCloseExamPointHistory}
      />
      <ExamRanking isOpen={isOpenExamRanking} onClose={onCloseExamRanking} />
      {redeemDesc && (
        <SuccessFulModal point={0} desc={redeemDesc}>
          <div className="flex w-full justify-center pt-8">
            <RedeemCouponFooter
              onClose={onClose}
              onSubmit={() => history.push('/coupon')}
              submitTitle="แลกคูปอง"
            />
          </div>
        </SuccessFulModal>
      )}
    </>
  );
};

export default MemberInfo;
