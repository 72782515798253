import axios from '@core/axios';
import { AxiosResponse } from 'axios';

export interface RedeemCouponPointResponse {
  result_code: string;
  result_desc: string;
}

const RedeemCouponPoint = (coupon_id: {
  coupon_id: string;
}): Promise<RedeemCouponPointResponse> => {
  return new Promise((resolve, reject) => {
    axios
      .post('/Member/redeemCoupon', coupon_id)
      .then((res: AxiosResponse<RedeemCouponPointResponse>) => {
        if (res.status === 200) {
          return resolve(res.data);
        }
      })
      .catch((err: any) => reject(err.response));
  });
};

export default RedeemCouponPoint;
