import React, { InputHTMLAttributes } from 'react';

const Select = React.forwardRef<HTMLSelectElement, InputHTMLAttributes<HTMLSelectElement>>((props, ref) => {
  return (
    <select
      ref={ref}
      className="appearance-none font-input-size-16 w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
      {...props}
    >
      {props.children}
    </select>
  );
});

export default Select;
