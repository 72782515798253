import Axios from 'axios';
import Input from '@components/Input/Input';
import { useEffect, useState } from 'react';
import SendOtpSMS from 'member/api/SendOtpSMS';
import Resend from 'assets/icon/re-send.svg';
import ValidateOtp from 'member/api/ValidateOtp';
import { useHistory } from 'react-router-dom';

interface PropType {
  line_id?: string;
  mobile_no?: string;
  onSave: (value: any) => void;
  onFailed: () => void;
}

const OtpForm: React.FC<PropType> = (props) => {
  const { line_id, mobile_no, onSave, onFailed } = props;

  const [mobileNo, setMobileNo] = useState(mobile_no);
  const [value, setValue] = useState<string>('');
  const [ref, setRef] = useState<string>('XXXXX');

  useEffect(() => {
    sendOtpSms();
  }, [mobile_no]);

  const sendOtpSms = () => {
    Axios.all([SendOtpSMS({ mobile_no, line_id })])
      .then(
        Axios.spread((...res: any) => {
          if (res[0].result_code !== '0') {
            alert(res[0].result_desc);
            onFailed();
            return;
          }

          if (res[0].ref_code) setRef(res[0].ref_code);

          if (res[0].mobile_no) setMobileNo(res[0].mobile_no);
        })
      )
      .catch((errors: any) => {
        console.log('Error get option value' + errors);
      });
  };

  const validateOtp = (otp: string) => {
    ValidateOtp({
      mobile_no: mobileNo,
      ref_code: ref,
      otp: otp,
    })
      .then((res) => {
        onSave({ otp_token: res.otp_token });
      })
      .catch((errors: any) => {
        console.log('Error get option value' + errors);
      });
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 6) return;

    setValue(e.target.value);

    if (e.target.value.length === 6) {
      validateOtp(e.target.value);
      return;
    }
  };

  return (
    <>
      <div className="text-center mt-4 mb-1">
        <label className="label-header">รหัส OTP</label>
        <div className="mt-4 mb-1">
          <p className="text">กรุณาป้อนรหัส OTP ที่ได้รับจาก SMS หมายเลข</p>
          <p className="text">
            {mobile_no} ที่คุณได้ลงทะเบียนไว้เพื่อยืนยันตัวตน
          </p>
        </div>
        <div className="mt-4 mb-1">
          <p className="text-ref">รหัสอ้างอิง: {ref} จะหมดอายุภายใน 5 นาที</p>
        </div>
        <div className="mx-8 my-4">
          <Input id="otp" type="number" value={value} onChange={onChange} />
        </div>
        <div className="flex justify-center mt-4 mb-1">
          <img className="mx-1" src={Resend} onClick={sendOtpSms} />
          <p className="text-ref underline mx-2" onClick={sendOtpSms}>
            ส่งรหัส OTP ใหม่
          </p>
        </div>
      </div>
    </>
  );
};

OtpForm.defaultProps = {
  onFailed: () => {},
};

export default OtpForm;
