import './exam-layout.scss';

import * as React from 'react';

import { useContext, useEffect, useRef, useState } from 'react';

import Button from '@components/Button';
import { ExamContext } from '@contexts/ExamContext';
import ExamPoint from 'exam/point/Point';
import PointSubmitComplete from 'exam/point/PointSubmitComplete';
import SoundIcon from '@components/Sound/SoundIcon';
import { getDateTimeServer } from '@core/@services/date-time.service';
import { useHistory } from 'react-router-dom';
import FailModal from '@components/FailModal';

export const ExamLayout: React.FC = ({ children }) => {
  const [playTime, setPlayTime] = useState(0);
  const timerRef = useRef<NodeJS.Timeout>();
  const {
    examInfo: { question_list },
    examNo,
    setExamNo,
    setEndDateTime,
    submitScore,
    answerList,
    setAnswerList,
    startDateTime,
  } = useContext(ExamContext);
  const [isOpenPointSumary, setIsOpenPointSumary] = useState(false);
  const [isOpenPointSubmit, setIsOpenPointSubmit] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const onClosePointSumary = () => setIsOpenPointSumary(false);
  const history = useHistory();

  useEffect(() => {
    setPlayTime(0);
  }, [startDateTime]);

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setPlayTime((time) => time + 1);
    }, 1000);
    return () => {
      clearTimeInterval();
    };
  }, [timerRef, playTime]);

  useEffect(() => {
    if (submitScore) {
      setIsOpenPointSubmit(true);
    }
  }, [submitScore]);

  function clearTimeInterval() {
    if (timerRef?.current) {
      clearInterval(timerRef.current);
    }
  }

  const renderTime = () => {
    const minute = Math.floor(playTime / 60);
    const second = playTime % 60;
    return (
      minute + ':' + (second < 10 ? '0' + second.toString() : second.toString())
    );
  };

  const onPevious = () => {
    const peviousExam = examNo - 1;

    if (peviousExam === 0) {
      setAnswerList([]);
      history.goBack();
    }

    if (peviousExam <= question_list?.length && peviousExam != 0) {
      setExamNo(peviousExam);
      return;
    }
  };

  const onNaxt = () => {
    if (!answerList[examNo - 1]) {
      setIsOpenModal(true);
      return;
    }
    const nextExam = examNo + 1;
    if (nextExam <= question_list?.length) {
      setExamNo(nextExam);
      return;
    }
    getDateTimeServer().then((data: any) => {
      clearTimeInterval();
      setEndDateTime(data.datetime);
      setIsOpenPointSumary(true);
    });
  };

  return (
    <div className="light-blue-bg fixed inset-0">
      <div className="header-bar absolute pr-6 flex w-full items-end justify-end">
        <div className="header-clock flex items-end justify-center">
          <p className="text-clock pb-3 pl-3 text-white font-bold tracking-wider">
            {renderTime()}
          </p>
        </div>
      </div>
      <div className="header z-50">
        <div className="absolute w-full px-4 pt-3">
          <SoundIcon />
        </div>
      </div>
      <div className="md:box-content mt-8">{children}</div>
      <ExamPoint isOpen={isOpenPointSumary} onClose={onClosePointSumary} />
      <PointSubmitComplete isOpen={isOpenPointSubmit} />
      <div className="exam-footer h-1/5 w-full fixed bottom-0 flex items-end">
        <Button className="h-5/6 w-2/5" onClick={onPevious}></Button>
        <Button className="h-5/6 w-3/5" onClick={onNaxt}></Button>
      </div>
      <FailModal
        isOpen={isOpenModal}
        message="กรุณาเลือกคำตอบของคุณ"
        onClose={() => setIsOpenModal(false)}
      />
    </div>
  );
};
