import axios from "@core/axios";
import { ExamListRequest } from "../menu.model";

const GetExamList = (data: ExamListRequest) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`/Member/reqExamList`, data)
            .then((res: any) => {
                if (res.status === 200) {
                    return resolve(res);
                }
            }).catch((err: any) => reject(err.response));
    });
};

export default GetExamList;