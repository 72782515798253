import React, { useCallback } from 'react';
import { useAppDispatch, useAppState } from '@contexts/AppContext';

import soundDisable from 'assets/layout/exam/sound-disable.svg';
import soundEnable from 'assets/layout/exam/sound-enable.svg';

function SoundIcon(): React.ReactElement {
  const { enableSound } = useAppState();
  const dispatch = useAppDispatch();

  const toggleStartStopBGAudio = useCallback(() => {
    dispatch({ type: 'SOUND', payload: !enableSound });
  }, [enableSound]);

  return (
    <img
      id="sound"
      src={enableSound ? soundEnable : soundDisable}
      onClick={toggleStartStopBGAudio}
    />
  );
}

export default SoundIcon;
