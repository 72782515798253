import axios from '@core/axios';
import {
  ExamInfo,
  FormInfo,
  PointResponse,
  Ranking,
  SubmitExam,
  SubmitExamResponse,
  SubmitFormInfoRequest,
  SubmitFormResponse,
  SubmitPointHistory,
} from 'exam/exam.model';

export const getExamInfo = (exam_id: string): Promise<ExamInfo> => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/Member/reqExamInfo`, {
        exam_id,
      })
      .then((res: any) => {
        if (res.status === 200) {
          return resolve(res.data);
        }
      })
      .catch((err: any) => reject(err.response));
  });
};

export const getFormInfo = (form_id: string): Promise<FormInfo> => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/Member/reqFormInfo`, {
        form_id,
      })
      .then((res: any) => {
        if (res.status === 200) {
          return resolve(res.data);
        }
      })
      .catch((err: any) => reject(err.response));
  });
};

export const submitExam = (
  answerExam: SubmitExam
): Promise<SubmitExamResponse> => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/Member/submitExamInfo`, answerExam)
      .then((res: any) => {
        if (res.status === 200) {
          return resolve(res.data);
        }
      })
      .catch((err: any) => reject(err.response));
  });
};

export const submitFormInfo = (
  data: SubmitFormInfoRequest
): Promise<SubmitFormResponse> => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/Member/submitFormInfo`, data)
      .then((res: any) => {
        if (res.status === 200) {
          return resolve(res.data);
        }
      })
      .catch((err: any) => reject(err.response));
  });
};

export const getExamRanking = (): Promise<Ranking> => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/Member/reqRanking`)
      .then((res: any) => {
        if (res.status === 200) {
          return resolve(res.data);
        }
      })
      .catch((err: any) => reject(err.response));
  });
};

export const getExamPointList = (data: SubmitPointHistory): Promise<PointResponse> => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/Member/reqPointList`, data)
      .then((res: any) => {
        if (res.status === 200) {
          return resolve(res.data);
        }
      })
      .catch((err: any) => reject(err.response));
  });
};
