import axios from '@core/axios';
import { AxiosResponse } from 'axios';
import { ValidateOtpRequest, ValidateOtpResponse } from 'member/member.model';

const ValidateOtp = (data: ValidateOtpRequest): Promise<ValidateOtpResponse> => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/Member/validateOtp`, data)
      .then((res: AxiosResponse<ValidateOtpResponse>) => {
        if (res.status === 200) {
          return resolve(res.data);
        }
      })
      .catch((err: any) => reject(err.response));
  });
};

export default ValidateOtp;
