import Axios from 'axios';
import FormLabel from '@components/FormLabel';
import { StdVersion, University } from 'member/member.model';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import GetUniversity from 'member/api/GetUniversity';
import Button from '@components/Button';
import GetStudentVersion from 'member/api/GetStudentVersion';
import Select from '@components/Select';

interface PropType {
  onSave: (value: any) => void;
}

const StdVeterinaryForm: React.FC<PropType> = (props) => {
  const { onSave } = props;

  const form = useForm();

  const [versions, setVersions] = useState<StdVersion[]>([]);
  const [universities, setUniversities] = useState<University[]>([]);

  useEffect(() => {
    Axios.all([GetUniversity(), GetStudentVersion()])
      .then(
        Axios.spread((...res: any) => {
          setUniversities(res[0]?.data ? res[0].data.university_list : []);
          setVersions(res[1]?.data ? res[1].data.student_version_list : []);
        })
      )
      .catch((errors: any) => {
        console.log('Error get option value' + errors);
      });
  }, []);

  const onSubmit = (data: any) => {
    onSave({ ...data, member_type_id: 3 });
  };

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <div className="w-full">
        <div className="mb-4 ml-8 mr-6">
          <FormLabel title="ชื่อมหาลัย" required></FormLabel>
          <Select
            id="university_name"
            type="text"
            {...form.register('university_name', { required: true })}
          >
            {universities.map((option) => {
              return (
                <option key={option.name} value={option.name}>
                  {option.name}
                </option>
              );
            })}
          </Select>
        </div>
        <div className="mb-4 ml-8 mr-6">
          <FormLabel title="เลขรุ่น" required></FormLabel>
          <Select
            id="student_version"
            type="text"
            {...form.register('student_version', { required: true })}
          >
            {versions.map((option) => {
              return (
                <option key={option.name} value={option.name}>
                  {option.name}
                </option>
              );
            })}
          </Select>
        </div>
      </div>
      <div className="register-type-form-btn text-center mb-4 mt-10">
        <Button
          className="btn-register hover:bg-blue-600 mb-3 tracking-wider"
          type="submit"
        >
          ยืนยัน
        </Button>
      </div>
    </form>
  );
};

export default StdVeterinaryForm;
