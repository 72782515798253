import BackArea from 'assets/layout/exam-info/back.svg';
import BackIcon from 'assets/layout/exam-info/back_icon.svg';
import Button from './Button';
import CSS from 'csstype';
import React from 'react';
import { useHistory } from 'react-router-dom';

const backContent: CSS.Properties = {
  background: 'url(' + BackArea + ') no-repeat',
  backgroundSize: 'cover',
  width: '42px',
  height: '35px',
};

export interface BackButtonProps {
  onClick?: () => void;
}
const BackButton: React.FC<BackButtonProps> = ({ ...props }) => {
  const history = useHistory();

  const onBack = () => {
    history.goBack();
  };

  return (
    <Button
      onClick={props.onClick ? props.onClick : onBack}
      className="flex items-center justify-center pr-1"
      style={backContent}
    >
      <img src={BackIcon} alt="back" />
    </Button>
  );
};
export default BackButton;
