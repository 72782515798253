import * as React from 'react';

import { CampaignInfo } from '@login/login.model';
import { SuccessFulModal } from './SuccessfulModal';
import { useLocation } from 'react-router-dom';

interface LocationState {
  campignInfo: CampaignInfo | null;
}

export const RegisterModal: React.FC = () => {
  const defaultCampignInfo: CampaignInfo = {
    campaign_desc: '',
    point: 0,
  };

  const { campignInfo } = useLocation<LocationState>().state;

  const [campign, setCampignInfo] = React.useState<CampaignInfo>(
    defaultCampignInfo
  );

  React.useEffect(() => {
    setCampignInfo(campignInfo ? campignInfo : defaultCampignInfo);
  }, []);

  return <SuccessFulModal point={campign.point} desc={campign.campaign_desc} />;
};
