import axios from '@core/axios';
import { AxiosResponse } from 'axios';
import { AcceptConsentRequest, AcceptConsentResponse } from '../term.model';

const PostAcceptConsentForm = (data: AcceptConsentRequest) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/Member/acceptConsentForm`, data)
      .then((res: AxiosResponse<AcceptConsentResponse>) => {
        if (res.status === 200) {
          return resolve(res.data);
        }
      })
      .catch((err: any) => reject(err.response));
  });
};

export default PostAcceptConsentForm;
