import liff from '@line/liff';

export class LiffService {
  static connect = async (
    liffId: string,
    callback?: (profile: {
      userId: string;
      displayName: string;
      pictureUrl?: string;
    }) => void
  ) => {
    liff.ready.then(() => {
      if (liff.isLoggedIn()) {
        liff.getProfile().then((re) => {
          callback && callback(re);
        });
      } else {
        liff.login();
      }
    });
    await liff.init({ liffId: `${liffId}` }).catch((err) => {
      throw err;
    });
  };

}
