import './member-info-edit.scss';

import { useEffect, useState } from 'react';
import Axios from 'axios';
import Button from '@components/Button';
import Autocomplete from '@autocomplete/Autocomplete';
import GetShopName from 'member/api/GetShopName';
import { useForm } from 'react-hook-form';
import {
  EmployeePosition,
  MemberType,
  PrefixRequest,
  Province,
  ShopList,
  StdVersion,
  University,
} from 'member/member.model';
import GetMemberInfo from 'member/api/GetMemberInfo';
import GetMemberType from 'member/api/GetMemberType';
import UpdateMemberInfo from 'member/api/UpdateMemberInfo';
import FailModal from '@components/FailModal';
import Popup from '@components/Popup';
import GetProvince from 'member/api/GetProvinces';
import GetUniversity from 'member/api/GetUniversity';
import FormLabel from '@components/FormLabel';
import Input from '@components/Input/Input';
import AutoComplete from '@components/AutoComplete/AutoComplete';
import { useHistory, useLocation } from 'react-router-dom';
import Select from '@components/Select';
import GetEmployeePosition from 'member/api/GetEmployeePosition';
import GetStudentVersion from 'member/api/GetStudentVersion';

interface LocationState {
  source?: string;
}

const inputClass =
  'appearance-none font-input-size-16 w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline';

const MemberInfoEdit = () => {
  const form = useForm();
  const state = useLocation<LocationState>();
  const history = useHistory();

  const [shopName, setShopName] = useState<ShopList[]>([]);
  const [memberTypes, setMemberTypes] = useState<MemberType[]>([]);

  const memberType = form.watch('member_type_id');

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [positions, setPositions] = useState<EmployeePosition[]>([]);
  const [provinces, setProvinces] = useState<Province[]>([]);
  const [universities, setUniversities] = useState<University[]>([]);
  const [versions, setVersions] = useState<StdVersion[]>([]);

  useEffect(() => {
    Axios.all([
      GetProvince(),
      GetUniversity(),
      GetEmployeePosition(),
      GetStudentVersion(),
    ])
      .then(
        Axios.spread((...res: any) => {
          setProvinces(res[0]?.data ? res[0].data.province_list : []);
          setUniversities(res[1]?.data ? res[1].data.university_list : []);
          setPositions(res[2]?.data ? res[2].data.emp_position_list : []);
          setVersions(res[3]?.data ? res[3].data.student_version_list : []);
        })
      )
      .catch((errors: any) => {
        console.log('Error get option value' + errors);
      });
  }, []);

  useEffect(() => {
    GetShopName({
      member_type_id: memberType,
    })
      .then((shop: any) => {
        setShopName(shop?.data ? shop.data.shop_list : []);
      })
      .catch((errors: any) => {
        console.log('Error get option value' + errors);
      });
  }, [memberType]);

  useEffect(() => {
    const req: PrefixRequest = {
      member_type_id: 2,
    };
    Axios.all([GetMemberInfo(), GetMemberType()])
      .then(
        Axios.spread((...res: any) => {
          const member_info = res[0]?.data || {};

          setMemberTypes(res[1]?.data?.member_type_list || []);

          form.setValue('member_type_id', member_info.member_type_id);
          form.setValue('first_name', member_info.first_name);
          form.setValue('last_name', member_info.last_name);
          form.setValue('vet_license_id', member_info.vet_license_id);
          form.setValue('emp_position', member_info.emp_position);
          form.setValue('province_name', member_info.province_name);
          form.setValue('university_name', member_info.university_name);
          form.setValue('student_version', member_info.student_version);
          form.setValue('mobile_no', member_info.mobile_no);
        })
      )
      .catch((errors: any) => {
        console.log('Error get option value' + errors);
      });
  }, []);

  const onSubmit = (data: any) => {
    const shop = shopName.find((shop) => shop.shop_name === data.shop_name);

    UpdateMemberInfo({ ...data, shop_id: shop?.shop_id })
      .then((res: any) => {
        if (res.already_mobile_no_use_flag) {
          alert('เบอร์โทรศัพท์ซ้ำในระบบ');
          return;
        }
        setIsOpen(true);
      })
      .catch((errors: any) => {
        console.log('Error get option value' + errors);
      });
  };

  const onClose = () => {
    setIsOpen(false);

    if (state?.state?.source) {
      history.push(state.state.source);
    }
  };

  return (
    <>
      <form
        className="edit-member-info-form"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <div className="w-full flex flex-col justify-between form">
          <div className="flex-auto">
            <div className="mb-4 ml-8 mr-6">
              <FormLabel title="ลักษณะงาน" required></FormLabel>
              <select
                className={inputClass}
                id="member_type_id"
                {...form.register('member_type_id')}
              >
                {memberTypes.map((t) => (
                  <option key={t.member_type_id} value={t.member_type_id}>
                    {t.member_type_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4 ml-8 mr-6">
              <FormLabel title="ชื่อ (ภาษาไทย)" required></FormLabel>
              <input
                className={inputClass}
                id="firstname"
                type="text"
                {...form.register('first_name', { required: true })}
              ></input>
            </div>
            <div className="mb-4 ml-8 mr-6">
              <FormLabel title="นามสกุล (ภาษาไทย)" required></FormLabel>
              <input
                className={inputClass}
                id="lastname"
                type="text"
                {...form.register('last_name', { required: true })}
              ></input>
            </div>
            <div className="mb-4 ml-8 mr-6">
              <FormLabel title="เบอร์มือถือ" required></FormLabel>
              <input
                className={inputClass}
                id="mobile_no"
                type="text"
                {...form.register('mobile_no', {
                  required: true,
                  minLength: 10,
                  maxLength: 10,
                })}
              ></input>
            </div>
            {memberType == 1 && (
              <>
                <div className="mb-4 ml-8 mr-6">
                  <FormLabel title="ตำแหน่ง/หน้าที่" required></FormLabel>
                  <Select
                    type="text"
                    {...form.register('emp_position', { required: true })}
                  >
                    {positions.map((option) => {
                      return (
                        <option key={option.name} value={option.name}>
                          {option.name}
                        </option>
                      );
                    })}
                  </Select>
                </div>
                <div className="mb-4 ml-8 mr-6">
                  <FormLabel title="จังหวัดที่ทำงาน" required></FormLabel>
                  <Select
                    type="text"
                    {...form.register('province_name', { required: true })}
                  >
                    {provinces.map((option) => {
                      return (
                        <option key={option.name} value={option.name}>
                          {option.name}
                        </option>
                      );
                    })}
                  </Select>
                </div>
              </>
            )}
            {memberType == 2 && (
              <>
                <div className="mb-4 ml-8 mr-6">
                  <FormLabel title="เลขใบประกอบวิชาชีพ" required></FormLabel>
                  <Input
                    id="vet_license_id"
                    type="text"
                    {...form.register('vet_license_id', { required: true })}
                  />
                </div>
                <div className="mb-4 ml-8 mr-6">
                  <FormLabel title="จังหวัดที่ทำงาน" required></FormLabel>
                  <Select
                    type="text"
                    {...form.register('province_name', { required: true })}
                  >
                    {provinces.map((option) => {
                      return (
                        <option key={option.name} value={option.name}>
                          {option.name}
                        </option>
                      );
                    })}
                  </Select>
                </div>
              </>
            )}
            {memberType == 3 && (
              <>
                <div className="mb-4 ml-8 mr-6">
                  <FormLabel title="ชื่อมหาลัย" required></FormLabel>
                  <Select
                    id="university_name"
                    type="text"
                    {...form.register('university_name', { required: true })}
                  >
                    {universities.map((option) => {
                      return (
                        <option key={option.name} value={option.name}>
                          {option.name}
                        </option>
                      );
                    })}
                  </Select>
                </div>
                <div className="mb-4 ml-8 mr-6">
                  <FormLabel title="เลขรุ่น" required></FormLabel>
                  <Select
                    id="student_version"
                    type="text"
                    {...form.register('student_version', { required: true })}
                  >
                    {versions.map((option) => {
                      return (
                        <option key={option.name} value={option.name}>
                          {option.name}
                        </option>
                      );
                    })}
                  </Select>
                </div>
              </>
            )}
          </div>
          <div className="text-center mb-4 mt-10">
            <Button
              className="btn-submit hover:bg-blue-600 mb-3 tracking-wider"
              type="submit"
            >
              บันทึก
            </Button>
          </div>
        </div>
      </form>
      <Popup text="บันทึกสำเร็จ" isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default MemberInfoEdit;
