import axios from '@core/axios';

const UpdateMemberInfo = (data) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`/Member/updateMemberInfoV2`, data)
            .then((res) => {
                if (res.status === 200) {
                    return resolve(res.data);
                }
            })
            .catch((err: any) => reject(err.response));
    });
};

export default UpdateMemberInfo;
