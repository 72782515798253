import * as React from 'react';

type AvatarProps = {
  background: string;
  color: string;
};
export const Avatar: React.FC<AvatarProps> = ({
  background,
  color,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 47 47"
      style={{ borderRadius: '50%', ...props }}
    >
      <path d="M0 0h47v47H0z" id="avatar-bg" fill={background} />
      <path
        fill={color}
        id="avatar-color"
        d="M11 34.234a12.732 12.732 0 013.8-8.946 8.244 8.244 0 014.9-2.388 11.3 11.3 0 011.4-.1c1.136-.007 2.273-.006 3.41-.006h1.918a8.718 8.718 0 015.593 1.884 11.478 11.478 0 012.942 3.628 12.656 12.656 0 011.526 6.025 1.053 1.053 0 01-1.14 1.151H23.758c-3.828 0-7.658-.013-11.487.011h-.02A1.166 1.166 0 0111 34.234zm23.3-.893c-.043-.314-.072-.615-.128-.911a10.939 10.939 0 00-2.239-4.821 6.741 6.741 0 00-5.482-2.683c-1.8-.009-3.6-.011-5.406 0a8.19 8.19 0 00-1.418.135 6.7 6.7 0 00-4.082 2.561 10.734 10.734 0 00-2.351 5.715 10.738 10.738 0 012.351-5.715 6.7 6.7 0 014.082-2.561 8.071 8.071 0 011.418-.134c1.8-.016 3.6-.013 5.405 0a6.742 6.742 0 015.483 2.683 10.939 10.939 0 012.239 4.821c.056.295.086.596.128.91zm-10.566-11.3a5.984 5.984 0 11.051 0zm.019-2.141zm.05 0a3.9 3.9 0 003.835-3.77A3.9 3.9 0 0123.8 19.9zm3.837-3.87a3.844 3.844 0 00-1.131-2.743 3.848 3.848 0 011.131 2.742zm-3.874-3.9z"
      />
    </svg>
  );
};
