import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import RegisterForm from './register-form/RegisterForm';
import OtpForm from './otp-form/OtpForm';
import RegisterTypeForm from './register-form/RegisterTypeForm';
import RegisterWithOTP from './api/RegisterWithOTP';
import { env } from '@core/env';
import { LiffService } from '@core/@services/Liff.service';

import '../member/Register.scss';

const Register = () => {
  const history = useHistory();

  const [data, setData] = useState({});
  const [state, setState] = useState('register-form');
  const [profile, setProfile] = useState<any>({});

  useEffect(() => {
    const line_liff = env.registerLiffId || '';

    LiffService.connect(line_liff, (value) => {
      setProfile(value);
    });
  }, []);

  const onSaveRegisterForm = (value: any) => {
    setData((data) => ({ ...data, ...value }));
    setState('otp');
  };

  const onSaveOtp = (value: any) => {
    setData((data) => ({ ...data, ...value }));

    if (value.otp_token) {
      setState('register-type-form');
    }
  };

  const onSaveRegisterTypeForm = (value: any) => {
    setData((data) => ({ ...data, ...value }));

    const { userId, displayName, pictureUrl } = profile;

    RegisterWithOTP({
      ...data,
      ...value,
      line_id: userId,
      line_name: displayName,
      profile_image: pictureUrl,
    })
      .then((res) => {
        if (res.result_code != '0' && res.result_code != '12') {
          alert(res.result_desc);
          return;
        }

        history.push('/login');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="md:container md:mx-auto register-form">
      {state === 'register-form' && (
        <RegisterForm onSave={onSaveRegisterForm} data={data} />
      )}
      {state === 'otp' && (
        <OtpForm
          mobile_no={data['mobile_no']}
          onSave={onSaveOtp}
          line_id={profile.userId}
          onFailed={() => setState('register-form')}
        />
      )}
      {state === 'register-type-form' && (
        <RegisterTypeForm onSave={onSaveRegisterTypeForm} />
      )}
    </div>
  );
};

export default Register;
