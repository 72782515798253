import axios from '@core/axios';

export const getDateTimeServer = () => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/Member/reqServerDateTime`)
      .then((res: any) => {
        if (res.status === 200) {
          return resolve(res.data);
        }
      })
      .catch((err: any) => reject(err.response));
  });
};
