import Button from '@components/Button'
import liff from '@line/liff/dist/lib';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import GetDocument from './api/GetDocument';
import { DocumentList } from './document.model';

function Document() {
    const [document, setDocument] = useState<DocumentList[]>([])
    const [documentName, setDocumentName] = useState<string>("ดาวน์โหลดเอกสาร\nประกอบการเรียน");

    useEffect(() => {
        GetDocument()
            .then((res: any) => {
                setDocument(res.document_list)
            })
            .catch((error) => {
                console.log(error, 'Get document error');
            });
    }, []);

    return (
        <div className="w-full h-full relative mt-10">
            <div className="w-full z-10 flex flex-col items-center">
                <div className="rope w-1/2 p-10 lg:p-40 md:p-32 sm:p-20"></div>
                <Button
                    type="button"
                    className="btn-menu-item text-3xl lg:text-7xl tracking-wider rounded-2xl lg:rounded-3xl white-space:pre-line"
                    disableSound
                >
                    {documentName}
                </Button>
            </div>
            <div className="grid grap-y-12" style={{ marginTop: '1rem' }}>
                {document.map((doc: DocumentList, index: number) => {
                    return (
                        <div
                            className="block sm:w-full md:w-full m-5 rounded-lg shadow-lg overflow-hidden bg-white h-148"
                            key={index}>
                            <div className="flex flex-row my-4 mx-4">
                                <div className="flex flex-col w-1/4">
                                    <img
                                        src={doc.document_cover_image}
                                        style={{ height: '102px', boxShadow: '0px 3px 6px #00000029' }}
                                        alt="img-document"
                                    />
                                </div>
                                <div className="flex flex-col w-3/4">
                                    <div className="flex flex-col my-3 mx-3">
                                        <div className="exam-title" style={{ whiteSpace: "pre-line" }}>
                                            {doc.document_title}
                                        </div>
                                        <Button
                                            className="label-coupon border-solid border rounded-3xl w-32 mt-2 bg-color-blue"
                                            onClick={() => {
                                                liff.openWindow({
                                                    url: doc.document_path,
                                                    external: true
                                                })
                                            }}
                                        >
                                            ดาวน์โหลด
                                        </Button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    )
                })}
            </div>
        </div >
    )
}

export default Document
