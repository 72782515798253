import Button from '@components/Button';
import React from 'react';

interface RedeemCouponFooterProps {
  onClose: () => void;
  onSubmit: () => void;
  submitTitle?: string;
}
const btnCloseClass =
  'btn-close z-30 tracking-wider rounded-2xl lg:rounded-3xl';

const btnSubmitClass =
  'btn-submit z-30 tracking-wider rounded-2xl lg:rounded-3xl';

const RedeemCouponFooter: React.FC<RedeemCouponFooterProps> = ({
  onClose,
  onSubmit,
  submitTitle = 'ยืนยัน',
}) => {
  return (
    <>
      <Button
        style={{ height: '43px', width: '92px' }}
        className={'button mr-4 ' + btnCloseClass}
        onClick={() => onClose()}
      >
        ปิด
      </Button>
      <Button
        style={{ height: '43px', width: '152px' }}
        className={'button ml-4 ' + btnSubmitClass}
        onClick={() => onSubmit()}
      >
        {submitTitle}
      </Button>
    </>
  );
};

export default RedeemCouponFooter;
