import Button from '@components/Button';
import liff from '@line/liff/dist/lib';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import PostAcceptConsentForm from './api/PostAcceptConsentForm';
import { AcceptConsentRequest } from './term.model';

import './term.scss';

interface LocationState {
  line_id?: string;
  source?: 'menu';
}

interface AcceptConsentForm {
  keep_data_flag?: 'true' | 'false';
  transfer_data_flag?: 'true' | 'false';
}

const TermsAndConditionsPage = () => {
  const form = useForm();
  const history = useHistory();
  const state = useLocation<LocationState>();

  const onAcceptConsent = (data: AcceptConsentRequest, path: string) => {
    PostAcceptConsentForm(data).then(() => {
      sessionStorage.setItem(
        'keep_data_flag',
        JSON.stringify(data.keep_data_flag)
      );
      sessionStorage.setItem(
        'transfer_data_flag_profile',
        JSON.stringify(data.transfer_data_flag)
      );

      if (
        !data.keep_data_flag &&
        state &&
        state.state &&
        state.state.source === 'menu'
      ) {
        console.log('close window');
        liff.closeWindow();
        return;
      }

      history.push(path);
    });
  };

  const onSubmit = (value: AcceptConsentForm) => {
    const data = {
      line_id: '',
      keep_data_flag: value.keep_data_flag === 'true',
      transfer_data_flag: value.transfer_data_flag === 'true',
    };

    if (state && state.state && state.state.line_id) {
      data.line_id = state.state.line_id;

      onAcceptConsent(data, '/login');
    } else {
      const profile = sessionStorage.getItem('liff-profile') || '{}';

      const { userId } = JSON.parse(profile);

      data.line_id = userId;

      const path = state?.state?.source || 'menu';

      onAcceptConsent(data, path);
    }
  };

  return (
    <form className="px-6 pb-6" onSubmit={form.handleSubmit(onSubmit)}>
      <h2 className="text-center text-3xl font-bold text-black">
        แบบให้ความยินยอม
      </h2>
      <p className="mt-6 text-2xl font-bold">
        ข้าพเจ้า ("เจ้าของขอมูล") ขอให้คำยินยอมของข้าพเจ้าต่อบริษัท ฮิลส์เพ็ท
        นูทริชั่น (ประเทศไทย) จำกัด ("บริษัท")
        ในฐานะผู้ควบคุมข้อมูลตามและเพื่อวัตถุประสงค์ของพระราชบัญญัติคุ้มครองข้อมูลส่วนบุลคล
        พ.ศ. 2562 เพื่อ
      </p>
      <ul className="mt-6">
        <li className="border-b list-item text-2xl">
          เก็บรวบรวม เก็บ ใช้ และเปิดเผย (ต่อผ่ายที่เกี่ยวข้องกับโครงการ)
          ซึ่งข้อมูลเกี่ยวกับชื่อ ไอดีไลน์ สถานที่ทำงาน ร่ยละเอียดติดต่อ ที่อยู่
          ประสบการณ์ หรือความคิดเห็นเกี่ยวกับผลิตภัณฑ์
          และความคิดเห็นพึงพอใจในผลิตภัณฑ์
          ของข้าพเจ้าผ่านการสำรวจการตลาดบนไลน์แอพพลิเคชั่น เพื่อวิเคราะห์
          ทำการตลาด หรือวัถตุประสงค์อื่นใด
          และเพื่นโอนข้อมูลส่วนบุคคลทั้งหมดตามที่ระบุข้างต้นให้แก่บริษัทในกลุ่มและบริษัทย่อย
          และผู้ให้บริการในการะเก็บรักษาข้อมูล ณ
          ที่ใดๆในโลกเพื่อเก็บข้อมูลที่ถูกต้องและเพื่อวัตถุประสงค์ใดๆ
          ที่ระบุข้างต้น
          <div className="flex items-center py-6">
            <div className="flex items-center flex-1">
              <input
                className="w-7 h-7"
                type="radio"
                value="true"
                {...form.register('keep_data_flag', { required: true })}
              />{' '}
              <div className="pl-6 font-bold text-2xl">ยินยอม</div>
            </div>
            <div className="flex items-center flex-1">
              <input
                className="w-7 h-7"
                type="radio"
                value="false"
                {...form.register('keep_data_flag', { required: true })}
              />{' '}
              <div className="pl-6 font-bold text-2xl">ไม่ยินยอม</div>
            </div>
          </div>
        </li>
        <li className="mt-6 border-b list-item text-2xl">
          เพื่อโอนข้อมูลส่วนบุคคลทั้งหมดตามที่ระบุข้างต้นให้แก่บริษัทในกลุ่มและบริษัทย่อย
          และบริษัทย่อย และผู้ให้บริการในการรักษาข้อมูลในประเทศใดๆ
          เพื่อข้อมูลที่ถูกต้องและเพื่อวัตถุประสงค์ใดๆ ที่ระบุข้างต้น
          <div className="flex items-center py-6">
            <div className="flex items-center flex-1">
              <input
                className="w-7 h-7"
                type="radio"
                value="true"
                {...form.register('transfer_data_flag', { required: true })}
              />{' '}
              <div className="pl-6 font-bold text-2xl">ยินยอม</div>
            </div>
            <div className="flex items-center flex-1">
              <input
                className="w-7 h-7"
                type="radio"
                value="false"
                {...form.register('transfer_data_flag', { required: true })}
              />{' '}
              <div className="pl-6 font-bold text-2xl">ไม่ยินยอม</div>
            </div>
          </div>
        </li>
      </ul>
      <div className="mt-6 text-2xl">ข้อควรทราบทั่วไป:</div>
      <div className="mt-6 text-2xl">
        1. การประมวลผลข้อมูลส่วนบุคคลของท่าน
        รวมทั้งการประมวลตามที่กล่าวมาข้างต้น
        อยู่ภายใต้ขอบเขตทั่วไปของนโยบายการคุ้มครองข้อมูลส่วนบุคคลสำหรับบุคคลภาพนอกของบริษัทฯ
        ("นโยบาย") โปรดศึกษานโยบายโดยละเอียดเพื่อทำความเข้าใจอย่างครบถ้วน
        และในกรณีใดๆ
        ให้ถือว่าแบบให้ความยินยอมสำหรับไลน์นี้เป็นเอกสารแนบท้ายนโยบายดังกล่าว
      </div>
      <div className="mt-6 text-2xl">
        2.
        การประมวลผลข้อมูลส่วนบุคคลของท่านตามที่ได้กล่าวถึงในแบบนี้กระทำขึ้นภายใต้ฐานความยินยอม
        บริษัทฯ จะแจ้งให้ท่านทราบในกรณีที่มีการเปลี่ยนแปลงฐานในการประมวลผล
      </div>
      <div className="mt-6 text-2xl">
        3. บริษัทฯ จะลบข้อมูลส่วนบุคคลของท่านเมื่อท่านเพิกถอนการให้ความยินยอม
        เว้นแต่กรณีที่บริษัทฯ มีฐานใหม่รองรับการเก็บข้อมูลนั้น
        ซึ่งหากเป็นเช่นนั้น บริษัทฯ จะแจ้งให้ท่านทราบ
      </div>
      <div className="mt-6 text-2xl">
        4. บริษัทฯ จะลบข้อมูลส่วนบุคคลของท่านเมื่อท่านเพิกถอนการให้ความยินยอม
        เว้นแต่กรณีที่บริษัทฯ มีฐานใหม่รองรับการเก็บข้อมูลนั้น
        ซึ่งหากเป็นเช่นนั้น บริษัทฯ จะแจ้งให้ท่านทราบ
      </div>
      <div className="mt-6 text-2xl">
        5. สิทธิของท่านที่อยู่ภายใต้กฎหมายมีดังนี้
      </div>
      <div className="mt-6 text-2xl">
        (1) สิทธิในการเข้าถึงข้อมูลส่วนบุคคลของท่าน
      </div>
      <div className="mt-6 text-2xl">
        (2) สิทธิในการรับรู้แหล่งที่มาในการเก็บรวบรวมข้อมูลส่วนบุคคลของท่าน
      </div>
      <div className="mt-6 text-2xl">
        (3) สิทธิในการถอนความยินยอมที่ท่านให้ไว้แก่บริษัทฯ
      </div>
      <div className="mt-6 text-2xl">
        (4) สิทธิในการร้องขอให้ลบ ทำงาย
        หรือทำให้ข้อมูลส่วนบุคคลไม่สามารถระบุตัวตนของท่านได้
      </div>
      <div className="mt-6 text-2xl">
        (5) สิทธิในการขอให้ระงับการใช้ข้อมูลส่วนบุคคลของท่าน
      </div>
      <div className="mt-6 text-2xl">
        (6) สิทธิในการขอสำเนาข้อมูลส่วนบุคคลของท่าน
      </div>
      <div className="mt-6 text-2xl">
        (7) สิทธิในการคัดค้านการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของท่าน
      </div>
      <div className="mt-6 text-2xl">
        (8) สิทธิในการเข้าถึงการบันทึกการติดต่อสื่อสารกับบริษัทฯ
        เกี่ยวกับข้อมูลส่วนบุคคลของท่าน
      </div>
      <div className="mt-6 text-2xl">
        (9) สิทธิในการร้องเรียนต่อบริษัทฯ
        เกี่ยวกับการประมวลผลข้อมูลส่วนบุคคลของท่าน
      </div>
      <div className="mt-6 text-2xl">
        (10) สิทธิในการร้องเรียนต่อผู้มีอานาจที่เกี่ยวข้องตามกฎหมาย
        ไม่ว่าจะด้วยเหตุผลใด
      </div>
      <div className="mt-6 text-2xl">
        6. หากมีข้อสงสัย โปรดติดต่อ
        <div className="text-2xl">
          บริษัทฯ ที บริษัท ฮิลส์ เพ็ท นู่ทริชั่น (ประเทศไทย) จำกัด
        </div>
        <div className="text-2xl">
          ที่อยู่: 19 ซอยแยกถนน ณ ระนอง คลองเตย กรุงเทพฯ 10110 ประเทศไทย
        </div>
        <div className="text-2xl">
          โทร: 1800-011-971 (วันจันทร์ - วันศุกร์) เวลา 08:30 - 16:30 น.
        </div>
        <div className="text-2xl">
          อีเมล: <span className="text-2xl">cathailand@colpal.com</span>
        </div>
      </div>
      <div className="flex justify-center mt-6">
        <Button
          className="btn-register hover:border-blue-600 mb-3"
          type="submit"
        >
          ยืนยัน
        </Button>
      </div>
    </form>
  );
};

export default TermsAndConditionsPage;
