import './App.scss';

import { AppProvider } from '@contexts/AppContext';
import BGSound from '@components/Sound/BGSound';
import { BrowserRouter } from 'react-router-dom';
import { RouteProvider } from '@contexts/RouteContext';
import { Router } from '@core/@routes/Router';

function App() {
  return (
    <AppProvider>
      <RouteProvider>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </RouteProvider>
      <BGSound />
    </AppProvider>
  );
}

export default App;
