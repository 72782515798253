import React, { useEffect } from 'react';

import { Information } from '@components/Information';
import LeftBackItem from '@components/LeftBackItem';
import liff from '@line/liff';
import { useAppDispatch } from '@contexts/AppContext';
import { ImageProfile } from '@components/ImageProfile';

export const MenuLayout: React.FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const closeWindow = () => {
    liff.closeWindow();
  };

  useEffect(() => {
    dispatch({ type: 'BG_SOUND', payload: true });
  }, []);

  return (
    <div className="fixed inset-0 wrapper-menu">
      <div className="header z-20">
        <div className="absolute w-full px-4 pt-3">
          <LeftBackItem onClick={closeWindow} />
          <Information />
        </div>
        <div className="absolute top-14 right-3">
          <div className="image-profile">
            <ImageProfile />
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};
