import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Coupon } from './coupon.model';
import './coupon-detail.scss';
import { getCouponInfo } from '@core/@services/coupon.service';
import parse from 'html-react-parser';
interface LocationState {
  coupon_id: string;
}

const CouponDetail = () => {
  const locationState = useLocation<LocationState>();
  const [coupon, setCoupon] = useState<Coupon>();

  useEffect(() => {
    const param = locationState?.state?.coupon_id ? locationState?.state.coupon_id : new URLSearchParams(locationState.search).get('coupon_id')
    if (param) {
      getCouponInfo(param).then((data) => {
        setCoupon(data);
      });
    }
  }, [locationState?.state?.coupon_id, locationState.search])

  return (
    <div className='absolute h-full overflow-scroll flex flex-col items-center pb-2'>
      <div className='header-label-top w-3/6 border-r-4 border-l-4 p-9'></div>
      <div className='header-label-bottom w-3/5 h-6'></div>
      <div className='flex flex-col items-center px-6 pb-8'>
        <img src={coupon?.coupon_image} className='flex flex-col w-5/6' />
        <div className='coupon-detail w-full mt-5 px-4 py-1'>
          <p className='text-3xl font-bold'>รายละเอียดการใช้คูปอง</p>
          <p className='text-2xl mt-2'>หมดอายุ: {coupon?.coupon_exp_date}</p>
          <p className='text-2xl mt-6'>{parse(coupon?.coupon_desc || "")}</p>
        </div>
      </div>
    </div>
  );
}

export default CouponDetail;
