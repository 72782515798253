import Axios from 'axios';
import Button from '@components/Button';
import FormLabel from '@components/FormLabel';
import { PrefixList, PrefixRequest } from 'member/member.model';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import GetPrefix from 'member/api/GetPrefix';
import Input from '@components/Input/Input';
import Select from '@components/Select';
import { useHistory } from 'react-router-dom';

interface PropsType {
  data: any;
  onSave: (value: any) => void;
}

const RegisterForm: React.FC<PropsType> = (props) => {
  const { data, onSave } = props;

  const form = useForm();
  const history = useHistory();

  const [dropDownOption, setDropDownOption] = useState<PrefixList[]>([]);

  useEffect(() => {
    form.setValue('prefix_id', data.prefix_id);
    form.setValue('first_name', data.first_name);
    form.setValue('last_name', data.last_name);
    form.setValue('birth_date', data.birth_date);
    form.setValue('mobile_no', data.mobile_no);
  }, [data]);

  useEffect(() => {
    Axios.all([GetPrefix({ member_type_id: 1 })])
      .then(
        Axios.spread((...res: any) => {
          setDropDownOption(res[0]?.data ? res[0].data.prefix_list : []);
          form.setValue('prefix_id', res[0].data.prefix_list[0].prefix_id);
        })
      )
      .catch((errors: any) => {
        console.log('Error get option value' + errors);
      });
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem('keep_data_flag') === 'true') {
      form.setValue('accepted_term', true);
    }
  }, []);

  const onClickTerms = () => {
    history.push('/terms-and-conditions', {
      source: '/',
    });
  };

  return (
    <>
      <div className="text-center mt-4 mb-1">
        <label className="label-header">กรุณากรอกข้อมูลเพื่อลงทะเบียน</label>
      </div>
      <form onSubmit={form.handleSubmit(onSave)}>
        <div className="w-full">
          <div className="mb-4 ml-8 mr-6">
            <FormLabel title="คำนำหน้า" required />
            <Select
              id="title"
              {...form.register('prefix_id', { required: true })}
            >
              {dropDownOption.map((option) => {
                return (
                  <option key={option.prefix_id} value={option.prefix_id}>
                    {option.prefix_name}
                  </option>
                );
              })}
            </Select>
          </div>
          <div className="mb-4 ml-8 mr-6">
            <FormLabel title="ชื่อ (ภาษาไทย)" required />
            <Input
              id="firstname"
              type="text"
              {...form.register('first_name', { required: true })}
            />
          </div>
          <div className="mb-4 ml-8 mr-6">
            <FormLabel title="นามสกุล (ภาษาไทย)" required />
            <Input
              id="lastname"
              type="text"
              {...form.register('last_name', { required: true })}
            />
          </div>
          <div className="mb-4 ml-8 mr-6">
            <FormLabel title="วัน / เดือน / ปีเกิด" required />
            <Input
              id="birthday"
              type="date"
              {...form.register('birth_date', { required: true })}
            />
          </div>
          <div className="mb-4 ml-8 mr-6">
            <FormLabel title="เบอร์มือถือ" required />
            <Input
              id="mobileNo"
              type="number"
              {...form.register('mobile_no', {
                required: true,
                minLength: 10,
                maxLength: 10,
              })}
            />
          </div>

          <div className="text-center mb-4 mt-10">
            <Button
              className="btn-register hover:bg-blue-600 mb-3 tracking-wider"
              type="submit"
            >
              ยืนยัน
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default RegisterForm;
