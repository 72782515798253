import { useEffect, useState } from 'react';

export default function useHeight(vh: number) {
  const [height, setHeight] = useState<string>(`${vh}vh`);

  useEffect(() => {
    const onResize = () => {
      setHeight(`${(window.innerHeight * vh) / 100}px`);
    };

    onResize();

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [vh]);

  return height;
}
