import '../ExamLayout/exam-layout.scss';

import * as React from 'react';

import { ExamButtonBG, ExamButtonType } from '@components/ExamButton';
import {
  ExamButtonStyle,
  SuccessFulModal,
  SuccessFulModalProps,
} from '@components/SuccessfulModal';
import { useContext, useState } from 'react';

import Button from '@components/Button';
import { ExamContext } from '@contexts/ExamContext';
import { SubmitFormResponse } from 'exam/exam.model';
import { submitFormInfo } from '@core/@services/exam.service';
import { useHistory } from 'react-router-dom';
import FailModal from '@components/FailModal';

export const FormLayout: React.FC = ({ children }) => {
  const {
    formInfo: { form_question_list },
    examNo,
    setExamNo,
    formAnswerList,
  } = useContext(ExamContext);
  const history = useHistory();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenPointSumary, setIsOpenPointSumary] = useState(false);
  const [data, setData] = useState<SuccessFulModalProps>({
    point: 0,
    desc: '',
  });

  const onPevious = () => {
    const peviousExam = examNo - 1;
    if (peviousExam === 0) {
      history.goBack();
    }
    if (peviousExam <= form_question_list?.length && peviousExam !== 0) {
      setExamNo(peviousExam);
      return;
    }
  };

  const onNaxt = () => {
    const question = form_question_list[examNo - 1];

    if (
      formAnswerList.question_value_list.findIndex(
        (value) => value.form_question_id === question.form_question_id
      ) < 0
    ) {
      setIsOpenModal(true);
      return;
    }

    const nextExam = examNo + 1;
    if (nextExam <= form_question_list?.length) {
      setExamNo(nextExam);
      return;
    }

    submitFormInfo(formAnswerList).then((response: SubmitFormResponse) => {
      const data: SuccessFulModalProps = {
        desc: response.result_desc,
        point: response.receive_point,
      };
      setData(data);
      setIsOpenPointSumary(true);
    });
  };

  return (
    <>
      <div className="light-blue-bg fixed inset-0">
        <div className="header-bar absolute pr-6 flex w-full items-end justify-end"></div>
        <div className="header z-50"></div>
        <div className="md:box-content mt-8">{children}</div>
        {isOpenPointSumary && (
          <SuccessFulModal desc={data.desc} point={data.point} />
        )}
        <div className="exam-footer h-1/5 w-full fixed bottom-0 flex items-end">
          <Button className="h-5/6 w-2/5" onClick={onPevious}></Button>
          <Button className="h-5/6 w-3/5" onClick={onNaxt}></Button>
        </div>
      </div>
      <FailModal
        isOpen={isOpenModal}
        message="กรุณาเลือกคำตอบของคุณ"
        onClose={() => setIsOpenModal(false)}
      />
    </>
  );
};
