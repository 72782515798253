import * as React from 'react';

import LeftBackItem from '@components/LeftBackItem';
import { useLocation } from 'react-router-dom';

interface LocationState {
  source?: string;
}

export const BackLayout: React.FC = ({ children }) => {
  const state = useLocation<LocationState>();

  return (
    <div className="flex">
      <div className="header z-50">
        {state?.state?.source !== '/otp' && (
          <div className="absolute w-full px-4 pt-3">
            <LeftBackItem showSound={false} />
          </div>
        )}
      </div>
      <div className="md:box-content w-full" style={{ paddingTop: '20%' }}>
        {children}
      </div>
    </div>
  );
};
