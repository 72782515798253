import OtpForm from 'member/otp-form/OtpForm';
import { useHistory, useLocation } from 'react-router-dom';

interface LocationState {
  line_id?: string;
  source?: string;
}

const OtpPage: React.FC = () => {
  const history = useHistory();
  const state = useLocation<LocationState>();

  const onSave = (value: any) => {
    if (value.otp_token === null) return;

    const path = state?.state?.source || 'menu';

    history.push(path);
  };

  return (
    <>
      <OtpForm onSave={onSave} onFailed={() => history.goBack()} />
    </>
  );
};

export default OtpPage;
