import axios from "@core/axios";

const GetFormList = () => {
    return new Promise((resolve, reject) => {
        axios
            .post(`/Member/reqFormList`, {})
            .then((res: any) => {
                if (res.status === 200) {
                    return resolve(res);
                }
            }).catch((err: any) => reject(err.response));
    });
};

export default GetFormList;