import ExamButton, { ExamButtonType } from '@components/ExamButton';
import ExamGotPoint from '@components/ExamGotPoint';
import React, { useContext, useEffect, useState } from 'react';

const PointRegisterComplete = (props: { isOpen?: boolean }) => {
  const { isOpen } = props;
  const [point] = useState<number>(10);

  return (
    <>
      {isOpen ? (
        <div
          className='modal-container flex flex-col justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none fade'
        >
          <ExamGotPoint point={point}>
            คุณได้คะแนนจาก การลงทะเบียนแล้ว
          </ExamGotPoint>
          <div className='flex'>
            <ExamButton type={ExamButtonType.ToClass}>
              เข้าสู่ห้องเรียน
            </ExamButton>
            <ExamButton type={ExamButtonType.ToRanking}>
              ดูลำดับของฉัน
            </ExamButton>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default PointRegisterComplete;
