import { useState } from 'react';
import StaffForm from './StaffForm';
import StdVeterinaryForm from './StdVeterinaryForm';
import VeterinaryForm from './VeterinaryForm';
import Button from '@components/Button';

interface PropType {
  onSave: (value: any) => void;
}

const RegisterTypeForm: React.FC<PropType> = (props) => {
  const { onSave } = props;

  const [state, setState] = useState('staff');

  return (
    <>
      <div className="text-center mt-4 mb-1">
        <label className="label-header">กรุณาเลือกลักษณะงานของคุณ</label>
        <div className="grid md:grid-flow-col grid-cols-3 mb-7 justify-items-center">
          <div
            className={
              state === 'staff'
                ? 'btn-menu btn-staff-active'
                : 'btn-menu btn-staff'
            }
            onClick={() => setState('staff')}
          ></div>
          <div
            className={
              state === 'veterinary'
                ? 'btn-menu btn-veterinary-active'
                : 'btn-menu btn-veterinary'
            }
            onClick={() => setState('veterinary')}
          ></div>
          <div
            className={
              state === 'std-veterinary'
                ? 'btn-menu btn-std-veterinary-active'
                : 'btn-menu btn-std-veterinary'
            }
            onClick={() => setState('std-veterinary')}
          ></div>
        </div>
      </div>
      <div>
        {state === 'staff' && <StaffForm onSave={onSave} />}
        {state === 'veterinary' && <VeterinaryForm onSave={onSave} />}
        {state === 'std-veterinary' && <StdVeterinaryForm onSave={onSave} />}
      </div>
    </>
  );
};

export default RegisterTypeForm;
