import StarScore from '@components/StarScore';
import popup_bg from 'assets/layout/exam/popup_bg.png';
import React from 'react';
import CSS from 'csstype';

const ExamGotPointBG: CSS.Properties = {
  background: 'url(' + popup_bg + ') no-repeat top',
  height: '350px',
};

const ExamGotPoint = ({ children, point }) => {
  return (
    <div
      style={ExamGotPointBG}
      className="pt-2 w-full flex flex-col justify-center items-center"
    >
      {point > 0 && (
        <StarScore color="#FFFFFF" fontColor="#265EA8">
          {point}
        </StarScore>
      )}
      <div className="whitespace-pre-wrap w-2/5 mt-2">
        <p className="text-4xl font-bold text-white text-center">{children}</p>
      </div>
    </div>
  );
};

export default ExamGotPoint;
