import { Avatar } from '@components/Avatar';
import Button from '@components/Button';
import { getExamRanking } from '@core/@services/exam.service';
import { Ranking } from 'exam/exam.model';
import React, { useEffect, useState } from 'react';
import styled, { CSSProperties } from 'styled-components';
import StarIcon from '../../assets/layout/exam-info/star.svg';
import TimeIcon from '../../assets/layout/exam/time.svg';
import LeaderIcon from '../../assets/layout/exam/winner.svg';
import './ranking.scss';

const imageStyle: CSSProperties = {
  display: 'inline-block',
  width: '39px',
  height: ' 39px',
  objectFit: 'cover',
  marginTop: '-1rem',
  borderRadius: '50%',
};

export const ExamRanking = ({ isOpen, onClose }) => {
  const [data, setData] = useState<Ranking>();

  useEffect(() => {
    getExamRanking().then((res) => {
      setData(res);
    });
  }, []);

  const defaultImage = (
    <Avatar {...imageStyle} background="#e6e7fd" color="#8d90fe" />
  );

  return (
    <>
      {isOpen && (
        <div className="exam-point-modal-container justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none fade">
          <div className="relative w-10/12 my-6 mx-auto max-w-3xl">
            <Button
              className="exam-point-modal-close h-14 w-1/6"
              onClick={onClose}
            ></Button>
            <div className="border-0 rounded-lg relative flex flex-col w-full outline-none focus:outline-none">
              <div className="exam-point-modal-header">
                <label className="exam-point-text-history">ลำดับคะแนน</label>
              </div>
              <div className="overflow-scroll exam-point-modal-content border-0 rounded-lg shadow-lg relative flex-auto text-center">
                <div className="justify-center mt-10 mb-2 flex">
                  <div
                    className={
                      data?.ranking_list[1].owner_flag
                        ? 'owner top-leader mt-6 border-radius-4px'
                        : 'top-leader mt-6 border-radius-4px'
                    }
                  >
                    <div>
                      <div className="text-center">
                        {data?.ranking_list[1].line_image ? (
                          <img
                            alt="profile"
                            src={data?.ranking_list[1].line_image}
                            style={imageStyle}
                          />
                        ) : (
                          defaultImage
                        )}
                      </div>
                      <label className="text-full-name-leader">
                        {data?.ranking_list[1].full_name}
                      </label>
                      <div className="flex justify-center items-center">
                        <img alt="." src={StarIcon} className="star-style" />
                        <label className="ml-1 text-point-leader">
                          {data?.ranking_list[1].point.toLocaleString()}
                        </label>
                      </div>
                      <div className="flex justify-center items-center mb-2 ml-2">
                        <img
                          width={12}
                          height={12}
                          alt="."
                          src={TimeIcon}
                          className="star-style"
                        />
                        <label className="ml-1 text-time-leader">
                          {data?.ranking_list[1].exam_time
                            ? data?.ranking_list[1].exam_time
                            : '00:00'}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      data?.ranking_list[0].owner_flag
                        ? 'owner top-leader ml-6 mr-6 border-radius-4px'
                        : 'top-leader ml-6 mr-6 border-radius-4px'
                    }
                  >
                    <div>
                      <div className="text-center">
                        {data?.ranking_list[0].line_image ? (
                          <img
                            alt="profile"
                            style={imageStyle}
                            src={data?.ranking_list[0].line_image}
                          />
                        ) : (
                          defaultImage
                        )}
                      </div>
                      <label className="text-full-name-leader">
                        {data?.ranking_list[0].full_name}
                      </label>
                      <div className="flex justify-center items-center">
                        <img alt="." src={StarIcon} className="star-style" />
                        <label className="ml-1 text-point-leader">
                          {data?.ranking_list[0].point.toLocaleString()}
                        </label>
                      </div>
                      <div className="flex justify-center items-center mb-2 ml-2">
                        <img
                          width={12}
                          height={12}
                          alt="."
                          src={TimeIcon}
                          className="star-style"
                        />
                        <label className="ml-1 text-time-leader">
                          {data?.ranking_list[0].exam_time
                            ? data?.ranking_list[0].exam_time
                            : '00:00'}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      data?.ranking_list[2].owner_flag
                        ? 'owner top-leader mt-6'
                        : 'top-leader mt-6'
                    }
                    style={{ borderRadius: '4px' }}
                  >
                    <div>
                      <div className="text-center">
                        {data?.ranking_list[2].line_image ? (
                          <img
                            alt="profile"
                            src={data?.ranking_list[2].line_image}
                            style={imageStyle}
                          />
                        ) : (
                          defaultImage
                        )}
                      </div>
                      <label className="text-full-name-leader">
                        {data?.ranking_list[2].full_name}
                      </label>
                      <div className="flex justify-center items-center">
                        <img alt="." src={StarIcon} className="star-style" />
                        <label className="ml-1 text-point-leader">
                          {data?.ranking_list[2].point.toLocaleString()}
                        </label>
                      </div>
                      <div className="flex justify-center items-center mb-2 ml-2">
                        <img
                          width={12}
                          height={12}
                          alt="."
                          src={TimeIcon}
                          className="star-style"
                        />
                        <label className="ml-1 text-time-leader">
                          {data?.ranking_list[2].exam_time
                            ? data?.ranking_list[2].exam_time
                            : '00:00'}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="inline-block mb-5" style={{ width: '100%' }}>
                  <img
                    alt="leader"
                    style={{ width: '87%' }}
                    className="inline-block"
                    src={LeaderIcon}
                  />
                </div>
                {data?.ranking_list.slice(3).map((d) => (
                  <div
                    key={d.member_id}
                    className={
                      d.owner_flag
                        ? 'owner exam-point-modal-box flex'
                        : 'exam-point-modal-box flex'
                    }
                  >
                    <div className="inline-block w-3/12">
                      <div>
                        <label className="text-rank">{d.no}</label>
                      </div>
                    </div>
                    <div className="inline-block w-3/6">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginTop: '0.5rem' }}>
                          {d.line_image ? (
                            <img
                              alt="line"
                              style={{
                                display: 'inline-block',
                                width: '25px',
                                height: '25px',
                                objectFit: 'cover',
                                borderRadius: '50%',
                                marginTop: '-0.5rem',
                              }}
                              src={d.line_image}
                            />
                          ) : (
                            defaultImage
                          )}
                        </div>
                        <div className="ml-2">
                          <label className="block text-xl font-bold text-full-name">
                            {d.full_name}
                          </label>
                          <div className="flex">
                            <img
                              width={12}
                              height={12}
                              alt="."
                              src={TimeIcon}
                            />
                            <label className="ml-1 text-time">
                              {d.exam_time ? d.exam_time : '00:00'}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="inline-block w-3/12">
                      <div className="items-center justify-center mr-3 flex">
                        <div className="w-10">
                          <img src={StarIcon} alt="star" />
                        </div>
                        <label className="ml-2 text-point">
                          {d.point.toLocaleString()}
                        </label>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ExamRanking;
