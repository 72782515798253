import { Information } from '@components/Information';
import * as React from 'react';
export const DefaultLayout: React.FC = ({ children }) => {
  return (
    <div className="flex flex-col overflow-x-hidden">
      <div className="wrapper absolute contents">
        <div className="header float-right">
          <Information />
        </div>
        <div className="md:box-content" style={{ paddingTop: '20%' }}>
          {children}
        </div>
      </div>
    </div>
  );
};
