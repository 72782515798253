import React, { createContext, useReducer } from 'react';

export type ActionType = 'SOUND' | 'BG_SOUND' | 'EXAM_BG_SOUND' | 'PAGE_ACTIVE';
type Action = {
  type: 'SOUND' | 'BG_SOUND' | 'EXAM_BG_SOUND' | 'PAGE_ACTIVE';
  payload: boolean;
};
type Dispatch = (action: Action) => void;
type State = {
  pageActive: boolean;
  enableSound: boolean;
  enableBgSound: boolean;
  enableExamBgSound: boolean;
};

const AppStateContext = createContext<State | undefined>(undefined);
const AppDispatchContext = createContext<Dispatch | undefined>(undefined);

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SOUND': {
      return {
        ...state,
        enableSound: action.payload,
      };
    }
    case 'PAGE_ACTIVE': {
      return {
        ...state,
        pageActive: action.payload,
      };
    }
    case 'BG_SOUND': {
      return {
        ...state,
        enableBgSound: action.payload,
        enableExamBgSound: false,
      };
    }
    case 'EXAM_BG_SOUND': {
      return {
        ...state,
        enableExamBgSound: action.payload,
        enableBgSound: action.payload ? false : true,
      };
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

const AppProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    pageActive: true,
    enableSound: false,
    enableBgSound: false,
    enableExamBgSound: false,
  });

  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
};

const useAppState = (): State => {
  const context = React.useContext(AppStateContext);
  if (context === undefined) {
    throw new Error('useAppState must be used within a AppProvider');
  }
  return context;
};
const useAppDispatch = (): Dispatch => {
  const context = React.useContext(AppDispatchContext);
  if (context === undefined) {
    throw new Error('useAppDispatch must be used within a AppProvider');
  }
  return context;
};

export { AppProvider, useAppState, useAppDispatch };
