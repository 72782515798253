import React, { useEffect } from 'react';

import { useAppDispatch } from './AppContext';
import { usePageVisibility } from 'react-page-visibility';

const RouteProvider: React.FC = ({ children }) => {
  const isVisible = usePageVisibility();
  const appDispatch = useAppDispatch();
  React.useEffect(() => {
    console.log('isVisible: ', isVisible);
    appDispatch({ type: 'PAGE_ACTIVE', payload: isVisible });
  }, [isVisible]);

  return <>{children}</>;
};

export { RouteProvider };
