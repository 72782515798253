import { ExamList, ExamListRequest, FormList } from './menu.model';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Button from '@components/Button';
import GetExamList from './api/GetExamList';
import GetFormList from './api/GetFormList';
import { ImageProfile } from '@components/ImageProfile';
import StartScore from '@components/StarScore';

const MenuDetail = () => {
  const [menuName, setMenuName] = useState<String>('');
  const [examList, setExamList] = useState<ExamList[]>([]);
  const { id }: any = useParams();
  const history = useHistory();

  useEffect(() => {
    if (id <= 2) {
      const req: ExamListRequest = {
        exam_type_id: id,
      };
      // แบบทดสอบ
      GetExamList(req)
        .then((res: any) => {
          setMenuName(res.data.exam_type_name);
          setExamList(res.data.exam_list);
        })
        .catch((error) => {
          console.log(error, 'Get exam list error');
        });
    } else {
      //(กิจกรรม)แบบสอบถาม
      GetFormList()
        .then((res: any) => {
          prepareFormList(res.data.form_list);
          setMenuName('กิจกรรม');
        })
        .catch((error) => {
          console.log(error, 'Get exam list error');
        });
    }
  }, []);

  const prepareFormList = (data: FormList[]) => {
    const result: ExamList[] = [];
    data.map((d: FormList) => {
      const exam: ExamList = {
        exam_cover_image: d.form_image,
        exam_title: d.form_title,
        exam_receive_point: 0,
        exam_point: d.form_point,
        exam_sp_point: 0,
        take_flag: d.take_flag,
        exam_content_type_id: d.form_content_type_id,
        exam_id: d.form_id,
        isForm: true,
      };
      result.push(exam);
    });
    setExamList(result);
  };

  const onClickExam = (
    type: number,
    examId: string,
    isForm: boolean = false,
    isTakeFlag: boolean
  ) => {
    const path = isForm ? 'form-info' : 'exam-info';
    console.log(isForm, type, isTakeFlag);
    if (isForm && type !== 1 && isTakeFlag) {
      return;
    }

    if (type === 1) {
      history.push(`/${path}/video`, { id: examId, menuId: id });
    } else {
      if (isForm) {
        history.push('/form', { id: examId });
      } else {
        history.push(`/${path}/graphic`, { id: examId, menuId: id });
      }
    }
  };

  return (
    <div className="w-full h-full relative mt-12">
      <div className="w-full z-10 flex flex-col items-center">
        <div className="rope w-1/2 p-10 lg:p-40 md:p-32 sm:p-20"></div>
        <Button
          type="button"
          className="btn-menu-item text-3xl lg:text-7xl tracking-wider rounded-2xl lg:rounded-3xl whitespace-pre-wrap"
          disableSound
        >
          {menuName}
        </Button>
      </div>
      <div className="grid grap-y-12" style={{ marginTop: '1rem' }}>
        {examList.map((exam: ExamList, index: number) => {
          return (
            <React.Fragment key={exam.exam_id}>
              <div
                className="block sm:w-full md:w-full m-5 rounded-lg shadow-lg overflow-hidden bg-white h-148"
                key={index}
                onClick={() =>
                  onClickExam(
                    exam.exam_content_type_id,
                    exam.exam_id,
                    exam.isForm,
                    exam.take_flag
                  )
                }
              >
                <img
                  src={exam.exam_cover_image}
                  className="w-full"
                  style={{ height: '102px' }}
                  alt="exam-cover"
                />
                <div className="align-middle grid grid-cols-4">
                  <div className="col-span-3">
                    <label className="mx-2 my-2 float-left exam-title m-auto whitespace-pre-wrap">
                      {exam.exam_title}
                    </label>
                  </div>
                  <div className="flex float-right">
                    <label className="mt-3">
                      <StartScore
                        children={
                          exam.isForm
                            ? exam.exam_point
                            : `${exam.exam_receive_point}/${exam.exam_point}`
                        }
                        color={exam.take_flag ? '#265EA8' : undefined}
                      />
                    </label>
                    <label className="mt-1 exam-multiply">
                      {exam.exam_sp_point > 0 ? `X2` : ``}
                    </label>
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};
export default MenuDetail;
