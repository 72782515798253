import * as React from 'react';

import LeftBackItem from '@components/LeftBackItem';

export const InformationLayout: React.FC = ({ children }) => {
  return (
    <div className="flex">
      <div className="header z-50">
        <div className="absolute w-full px-4 pt-3">
          <LeftBackItem />
        </div>
      </div>
      <div className="md:box-content" style={{ paddingTop: '20%' }}>
        {children}
      </div>
    </div>
  );
};
