import * as React from 'react';

import Coupon from 'coupon/Coupou';
import CouponDetail from 'coupon/CouponDetail';
import { CouponLayout } from '@layouts/CouponLayout/CouponLayout';
import CoupouList from 'coupon/CoupouList';
import { CustomLayout } from './CustomLayout';
import { DefaultLayout } from '@layouts/DefaultLayout';
import Exam from 'exam/Exam';
import ExamContextProvider from '@contexts/ExamContext';
import { ExamInfoLayout } from '@layouts/ExamInfoLayout/ExamInfoLayout';
import { ExamInfoVideo } from 'exam/info-video/ExamInfoVideo';
import { ExamLayout } from '@layouts/ExamLayout/ExamLayout';
import { Form } from 'exam/Form';
import { FormInfoLayout } from '@layouts/FormInfoLayout/FormInfoLayout';
import { FormInfoVideo } from 'exam/info-video/FormInfoVideo';
import { FormLayout } from '@layouts/FormLayout/FormLayout';
import InfoGraphic from 'exam/info-graphic/InfoGraphic';
import { InformationLayout } from '@layouts/InformationLayout';
import InformationPage from '@pages/Information/Information.Page';
import Login from '@login/Login';
import LoginPoint from '@login/LoginPoint';
import MemberInfo from 'member/member-info/MemberInfo';
import { MemberInfoLayout } from '@layouts/MemberInfoLayout/MemberInfoLayout';
import MenuDetail from '@pages/Menu/MenuDetail';
import { MenuDetailLayout } from '@layouts/MenuDetailLayout';
import { MenuLayout } from '@layouts/MenuLayout';
import MenuPage from '@pages/Menu/Menu.Page';
import { PageProvider } from '@contexts/PageContext';
import Register from 'member/Register';
import { RegisterLayout } from '@layouts/RegisterLayout';
import { RegisterModal } from '@components/RegisterModal';
import { Route } from 'react-router-dom';
import SeeMore from 'exam/info-graphic/SeeMore';
import { Switch } from 'react-router';
import TestPage from 'exam/TestPage';
import Document from '@document/Document';
import { DocumentLayout } from '@layouts/DocumentLayout';
import MemberInfoEdit from 'member/member-info-edit/MemberInfoEdit';
import { BackLayout } from '@layouts/BackLayout/BackLayout';
import TermsAndConditionsPage from '@pages/TermsAndConditions/TermsAndConditions.Page';
import OtpPage from '@pages/Otp/Otp.Page';

export const Router: React.FC = () => {
  return (
    <Switch>
      <Route path={['/']} exact>
        <CustomLayout layout={DefaultLayout}>
          <Route path="/" exact component={Register} />
        </CustomLayout>
      </Route>
      <Route path="/register">
        <CustomLayout layout={DefaultLayout}>
          <Route component={Register} exact path="/register" />
        </CustomLayout>
      </Route>
      <Route path="/login">
        <CustomLayout layout={DefaultLayout}>
          <Route component={Login} exact path="/login" />
        </CustomLayout>
      </Route>
      <PageProvider>
        <Route path="/point">
          <CustomLayout layout={DefaultLayout}>
            <Route component={LoginPoint} exact path="/point" />
          </CustomLayout>
        </Route>
        <Route path="/menu-detail">
          <CustomLayout layout={MenuDetailLayout}>
            <Route component={MenuDetail} exact path="/menu-detail/:id" />
          </CustomLayout>
        </Route>
        <Route path="/menu">
          <CustomLayout layout={MenuLayout}>
            <Route component={MenuPage} exact path="/menu" />
          </CustomLayout>
        </Route>
        <Route path="/information">
          <CustomLayout layout={InformationLayout}>
            <Route component={InformationPage} exact path="/information" />
          </CustomLayout>
        </Route>
        <Route path="/member-info">
          <CustomLayout layout={MemberInfoLayout}>
            <Switch>
              <Route component={MemberInfo} exact path="/member-info" />
            </Switch>
          </CustomLayout>
        </Route>
        <Route path="/member-info-edit">
          <CustomLayout layout={BackLayout}>
            <Switch>
              <Route
                component={MemberInfoEdit}
                exact
                path="/member-info-edit"
              />
            </Switch>
          </CustomLayout>
        </Route>
        <Route path="/coupon-info">
          <CustomLayout layout={CouponLayout}>
            <Switch>
              <Route component={Coupon} exact path="/coupon-info" />
            </Switch>
          </CustomLayout>
        </Route>
        <Route path="/coupon">
          <CustomLayout layout={MemberInfoLayout}>
            <Switch>
              <Route component={CoupouList} exact path="/coupon" />
            </Switch>
          </CustomLayout>
        </Route>
        <Route path="/coupon-detail">
          <CustomLayout layout={CouponLayout}>
            <Switch>
              <Route component={CouponDetail} exact path="/coupon-detail" />
            </Switch>
          </CustomLayout>
        </Route>
        <Route path="/document">
          <CustomLayout layout={DocumentLayout}>
            <Switch>
              <Route component={Document} exact path="/document" />
            </Switch>
          </CustomLayout>
        </Route>
        <Route path="/success-register-modal">
          <CustomLayout layout={RegisterLayout}>
            <Route
              component={RegisterModal}
              exact
              path="/success-register-modal"
            />
          </CustomLayout>
        </Route>
        <ExamContextProvider>
          <Route path="/exam-info">
            <CustomLayout layout={ExamInfoLayout}>
              <Switch>
                <Route component={TestPage} exact path="/exam-info" />
                <Route
                  component={InfoGraphic}
                  exact
                  path="/exam-info/graphic"
                />
                <Route component={SeeMore} exact path="/exam-info/see-more" />
                <Route
                  component={ExamInfoVideo}
                  exact
                  path="/exam-info/video"
                />
              </Switch>
            </CustomLayout>
          </Route>
          <Route path="/exam">
            <CustomLayout layout={ExamLayout}>
              <Switch>
                <Route component={Exam} exact path="/exam" />
              </Switch>
            </CustomLayout>
          </Route>
          <Route path="/form-info">
            <CustomLayout layout={FormInfoLayout}>
              <Switch>
                <Route
                  component={FormInfoVideo}
                  exact
                  path="/form-info/video"
                />
              </Switch>
            </CustomLayout>
          </Route>
          <Route path="/form">
            <CustomLayout layout={FormLayout}>
              <Switch>
                <Route component={Form} exact path="/form" />
              </Switch>
            </CustomLayout>
          </Route>
          <Route path="/terms-and-conditions">
            <CustomLayout layout={DefaultLayout}>
              <Switch>
                <Route component={TermsAndConditionsPage} exact path="/terms-and-conditions" />
              </Switch>
            </CustomLayout>
          </Route>
          <Route path="/otp">
            <CustomLayout layout={DefaultLayout}>
              <Switch>
                <Route component={OtpPage} exact path="/otp" />
              </Switch>
            </CustomLayout>
          </Route>
        </ExamContextProvider>
      </PageProvider>
    </Switch>
  );
};
