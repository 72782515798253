import { ExamContext } from '@contexts/ExamContext';
import React, { useContext } from 'react';
import './see-more.scss';

const SeeMore = () => {
    const { examInfo : { exam_title, exam_desc } } = useContext(ExamContext);

    return (
    <div className="see-more absolute mt-20 mr-8 ml-8 whitespace-pre-wrap">
      <p className="w-full text-3xl font-bold">{exam_title}</p>
      <p className="text-2xl mt-2">{exam_desc}</p>
    </div>
  );
};

export default SeeMore;
