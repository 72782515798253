import React from 'react';

const InformationPage = () => {
  return (
    <div className="h-screen overflow-auto">
      <div className="p-4">
        <p className="text-3xl font-bold text-black">ข้อมูลอธิบายการใช้งาน</p>
        <div className="my-3">
          <span className="text-2xl">
            Hill’s School เป็นแพลทฟอร์มการเรียนรู้ในรูปแบบออนไลน์
            อยู่ภายใต้ลิขสิทธิ์ของบริษัท ฮิลส์ เพ็ท นูทริชั่น (ประเทศไทย) จำกัด
            และได้รับมอบหมายให้จัดทำและดูแลข้อมูลโดยบริษัท พีเบอร์รี่ สตูดิโอ
            จำกัด
            หากท่านต้องการสอบถามข้อมูลเพิ่มเติมหรือต้องการแจ้งยกเลิกเงื่อนไขนโยบายควา
            มเป็นส่วนตัว หรือต้องการปรึกษาเกี่ยวกับสุขภาพสัตว์เลี้ยง
            <span className="text-2xl font-bold">
              กรุณาติดต่อศูนย์บริการให้คำปรึษา หมายเลขโทรศัพท์ 02- 229-8200
              ตั้งแต่วันจันทร์ - ศุกร์ เวลา 08.00 - 16.30 น.
            </span>
          </span>
        </div>
        <p className="text-3xl font-bold text-black">
          ขั้นตอนการสมัครใช้งาน Hill’s School
        </p>
        <div className="my-3">
          <ol className="list-decimal  pl-8">
            <li className="text-2xl">
              Add Line @hillsschool จากนั้นกดปุ่มลงทะเบียนที่เมนูด้านล่าง
            </li>
            <li className="text-2xl">
              กรอกข้อมูลส่วนตัวของท่าน จากนั้นกดยืนยัน
            </li>
            <li className="text-2xl">เริ่มใช้งานแพลทฟอร์มได้ทันที</li>
          </ol>
        </div>
        <p className="text-3xl font-bold text-black">ขั้นตอนการสะสมคะแนน</p>
        <div className="my-3">
          <div className="text-2xl">
            ท่านสามารถได้รับคะแนนสะสม จากการร่วมกิจกรรมของ Hill’s School
            ดังต่อไปนี้
          </div>
          <ol className="list-decimal py-4 pl-8">
            <li className="text-2xl">
              รับชมสื่อการเรียนรู้ในรูปแบบ VDO
              และ/หรือตอบคำถามหลังจากรับชมให้ถูกต้อง
            </li>
            <li className="text-2xl">
              อ่านบทความให้ความรู้ พร้อมทั้งตอบคำถามท้ายบทความให้ถูกต้อง
            </li>
            <li className="text-2xl">
              สำหรับท่านที่ลงทะเบียนเข้าใช้งานครั้งแรก รับคะแนนพิเศษแรกเข้า
            </li>
            <li className="text-2xl">
              ท่านจะได้รับคะแนนพิเศษ เมื่อถึงวันเกิดของท่าน
            </li>
          </ol>
          <div className="text-2xl">
            คะแนนที่สะสม จะมีอายุ 1 ปี (นับจากวันที่เริ่มสะสม)
            โดยท่านสามารถตรวจสอบคะแนนและประวัติการได้รับ/แลกคะแนนได้จากหน้าโปรไฟล์ของตนเอง
          </div>
        </div>
        <p className="text-3xl font-bold text-black">ขั้นตอนการแลกคะแนน</p>
        <div className="my-3">
          <div className="text-2xl">เมื่อท่านสะสมคะแนนถึงเกณฑ์ที่กำหนด</div>
          <div className="text-2xl">
            ท่านสามารถเลือกแลกคะแนนเพื่อรับคูปองของรางวัลได้
            โดยเข้าไปที่หน้าโปรไฟล์กดปุ่มแลกคะแนน
            และเลือกคูปองของรางวัลที่ท่านต้องการ
          </div>
          <div className="text-2xl">
            คะแนนที่แลกไปแล้ว ไม่สามารถขอเปลี่ยนหรือคืนได้
          </div>
        </div>
        <p className="text-3xl font-bold text-black">การจัดอันดับคะแนนสูงสุด</p>
        <div className="my-3">
          <div className="text-2xl">
            ท่านสามารถตรวจสอบอันดับคะแนนของท่านเมื่อเปรียบเทียบกับผู้ใช้งานท่านอื่นๆโดยจะเรียงลำดับตามคะแนนและการใช้เวลาในการทำข้อสอบน้อยที่สุด
          </div>
          <div className="text-2xl">
            โดยผู้ที่อยู่ในอันดับสูงสุด 5 อันดับแรก จะได้รับ Xiaomi Watch Lite
            ท่านละ 1 รางวัล (ประกาศผลในเดือนธันวาคม 2564)
          </div>
        </div>
      </div>
    </div>
  );
};

export default InformationPage;
