import RopeAndHeader from '@components/RopeAndHeader';
import {
  SuccessFulModal,
  SuccessFulModalProps,
} from '@components/SuccessfulModal';
import { useAppDispatch } from '@contexts/AppContext';
import { submitFormInfo } from '@core/@services/exam.service';
import { SubmitFormInfoRequest, SubmitFormResponse } from 'exam/exam.model';
import React, { useLayoutEffect, useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import './info-video.scss';

export interface InfoVideoProps {
  title: string;
  desc: string;
  video: string;
  isTakeFlag?: boolean;
  isForm?: boolean;
  formId?: string;
}
export const InfoVideo: React.FC<InfoVideoProps> = ({
  title,
  desc,
  video,
  isTakeFlag,
  isForm,
  formId,
}) => {
  const dispatch = useAppDispatch();
  const [isOpenPointSumary, setIsOpenPointSumary] = useState(false);
  const [data, setData] = useState<SuccessFulModalProps>({
    point: 0,
    desc: '',
  });

  useLayoutEffect(() => {
    const videoContainer = document.getElementById('video-container');
    if (videoContainer) {
      dispatch({ type: 'SOUND', payload: false });
      const timeout = setTimeout(() => {
        videoContainer.style.width = '100%';
        videoContainer.style.height = '100%';
      }, 1000);

      return () => {
        clearTimeout(timeout);
      };
    }
  });

  const handleEnded = () => {
    dispatch({ type: 'SOUND', payload: true });
    if (isForm && !isTakeFlag && formId) {
      const param: SubmitFormInfoRequest = {
        form_id: formId,
        question_value_list: [],
      };
      submitFormInfo(param).then((response: SubmitFormResponse) => {
        const data: SuccessFulModalProps = {
          desc: response.result_desc,
          point: response.receive_point,
        };
        setData(data);
        setIsOpenPointSumary(true);
      });
    }
  };

  return (
    <>
      <RopeAndHeader>{title}</RopeAndHeader>
      <div className="justify-center p-10" style={{ padding: '2.5rem 1.5rem' }}>
        <div
          id="video-container"
          className="text-center flex w-full justify-center video-container"
        >
          <ReactPlayer
            playsinline
            url={video}
            controls={true}
            height="100%"
            width="100%"
            config={{
              vimeo: {
                playerOptions: {
                  autoplay: true,
                  responsive: true,
                },
              },
            }}
            onEnded={handleEnded}
          />
        </div>
        <h1 className="w-full mt-6 ml-0 text-3xl whitespace-pre-wrap">
          {desc}
        </h1>
      </div>
      {isOpenPointSumary && (
        <SuccessFulModal desc={data.desc} point={data.point} />
      )}
    </>
  );
};
