import { ExamContext } from '@contexts/ExamContext';
import React, { useContext } from 'react';
import './info-video.scss';
import { InfoVideo } from './InfoVideo';

export const ExamInfoVideo: React.FC = () => {
  const {
    examInfo: { exam_title, exam_video, exam_desc },
  } = useContext(ExamContext);

  return (
    <InfoVideo
      title={exam_title || ''}
      desc={exam_desc || ''}
      video={exam_video || ''}
    />
  );
};
