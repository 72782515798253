import $axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { Token } from './core.model';
import { env } from './env';

const config: AxiosRequestConfig = {
  baseURL: env.apiURL,
  headers: {
    'Content-Type': 'application/json',
  },
  proxy: false,
};

// const baseURL = env.apiURL;
// const axios: AxiosInstance = $axios.create({ baseURL });
const axios: AxiosInstance = $axios.create(config);

axios.interceptors.request.use(
  (config) => {
    const storageToken = sessionStorage.getItem('hillToken');
    if (storageToken) {
      const token: Token = {};
      Object.assign(token, JSON.parse(storageToken));
      if (token.access_token) {
        config.headers['X-HILL-AccessToken'] = token.access_token;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error.response);
  }
);

export default axios;
