import {
  ExamInfo,
  FormInfo,
  SubmitExamAnswerList,
  SubmitFormInfoRequest,
} from 'exam/exam.model';
import React, { useState } from 'react';

interface ExamContextInterface {
  examInfo: ExamInfo;
  setExamInfo: any;
  startDateTime: string;
  setStartDateTime: any;
  endDateTime: string;
  setEndDateTime: any;
  examNo: number;
  setExamNo: any;
  answerList: SubmitExamAnswerList[];
  setAnswerList: any;
  submitScore: boolean;
  setSubmitScore: any;
  formInfo: FormInfo;
  setFormInfo: any;
  formAnswerList: SubmitFormInfoRequest;
  setFormAnswerList: any;
}

export const ExamContext = React.createContext<ExamContextInterface>({
  examInfo: {
    exam_id: '',
    question_list: [],
  },
  setExamInfo: null,
  startDateTime: '',
  setStartDateTime: null,
  endDateTime: '',
  setEndDateTime: null,
  examNo: 1,
  setExamNo: null,
  answerList: [],
  setAnswerList: null,
  submitScore: false,
  setSubmitScore: null,
  formInfo: {
    form_id: '',
    form_question_list: [],
  },
  setFormInfo: null,
  formAnswerList: {
    form_id: '',
    question_value_list: [],
  },
  setFormAnswerList: null,
});

const ExamContextProvider = (props: { children: React.ReactNode }) => {
  const [examInfo, setExamInfo] = useState({
    exam_id: '',
    exam_point: 99,
    exam_title: "Story's Name",
    exam_infographic_image:
      'https://www.hillsschoolth.com/API/ExamImage/6c588c43-0025-45f1-8110-a4911c51e952.jpg',
    take_flag: false,
    question_list: [
      {
        question_title: '',
        question_id: '',
        point: 0,
        group_no: 0,
        answer_list: [],
      },
    ],
  });

  const [formInfo, setFormInfo] = useState({
    form_id: '',
    form_title: 'Test ACTIVIRY แบบสอบถาม Radio',
    form_desc: ' รายละเอียด Test ACTIVIRY แบบสอบถาม Radio',
    form_image:
      'https://www.hillsschoolth.com/API/FormImage/78da1fa1-802c-4143-8568-dff71f308351.jpg',
    score: 10,
    form_content_type_id: 0,
    take_flag: false,
    form_question_list: [
      {
        form_question_id: '',
        form_question_title: '',
        form_question_detail_default: '',
        require_field: false,
        order_no: 1,
        input_type_id: 7,
        input_type_name: '',
      },
    ],
  });

  const [startDateTime, setStartDateTime] = useState('');
  const [endDateTime, setEndDateTime] = useState('');
  const [examNo, setExamNo] = useState(1);
  const [answerList, setAnswerList] = useState([]);
  const [formAnswerList, setFormAnswerList] = useState({
    form_id: '',
    question_value_list: [],
  });
  const [submitScore, setSubmitScore] = useState(false);

  return (
    <ExamContext.Provider
      value={{
        examInfo,
        setExamInfo,
        startDateTime,
        setStartDateTime,
        endDateTime,
        setEndDateTime,
        examNo,
        setExamNo,
        answerList,
        setAnswerList,
        submitScore,
        setSubmitScore,
        formInfo,
        setFormInfo,
        formAnswerList,
        setFormAnswerList,
      }}
    >
      {props.children}
    </ExamContext.Provider>
  );
};

export default ExamContextProvider;
