import axios from "@core/axios";
import { AxiosResponse } from "axios";
import { CouponList } from "coupon/coupon.model";

const GetCouponList = () => {
    return new Promise((resolve, reject) => {
        axios
            .post("/Member/reqCouponList", {})
            .then((res: AxiosResponse<CouponList>) => {
                if (res.status === 200) {
                    return resolve(res);
                }
            }).catch((err: any) => reject(err.response));
    });
};

export default GetCouponList;