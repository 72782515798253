import React, { useState } from 'react';

import Button from '@components/Button';
import ExamPointHistory from './point-history/PointHistory';
import ExamRanking from './ranking/Ranking';
import PointRegisterComplete from 'member/PointRegisterComplete';
import RedeemCoupon from 'coupon/redeem-coupon/RedeemCoupon';
import { useHistory } from 'react-router-dom';

const TestPage = () => {
  const history = useHistory();
  const [isOpenHistory, setIsOpenHistory] = useState(false);
  const [isOpenRaking, setIsOpenRaking] = useState(false);
  const [isOpenPointRegisterComplete, setIsPointRegisterComplete] =
    useState(false);
  const [isOpenRedeemCoupon, setIsOpenRedeemCoupon] = useState(false);


  const onExamVideo = (id) => {
    history.push('/exam-info/video', { id });
  };

  const onExamGraphic = (id) => {
    history.push('/exam-info/graphic', { id });
  };

  const onCouponDetail = (coupon_id) => {
    history.push('/coupon-detail', { coupon_id });
  };

  const onCloseHistory = () => {
    setIsOpenHistory(false);
  };

  const onCloseRaking = () => {
    setIsOpenRaking(false);
  };

  const onCloseRedeemCoupon = () => {
    setIsOpenRedeemCoupon(false);
  };

  return (
    <div className="see-more absolute mt-20 mr-8 ml-8 whitespace-pre-wrap">
      <Button
        onClick={() => onExamVideo('2d470dc0-3f41-4e1c-92d3-73e0b3aedf88')}
      >
        Exam 1
      </Button>
      <br />
      <Button
        onClick={() => onExamGraphic('4e170c0b-a3e6-4a6c-b9be-0e7e6c626bfc')}
      >
        Exam 2
      </Button>
      <br />
      <Button
        onClick={() => onCouponDetail('ea2252de-efe1-4eb1-8bb5-ec3824645b28')}
      >
        coupon detail
      </Button>
      <br />
      <Button onClick={() => setIsPointRegisterComplete(true)}>
        PointRegisterComplete!
      </Button>
      <PointRegisterComplete isOpen={isOpenPointRegisterComplete} />
      <br />
      <Button onClick={() => setIsOpenHistory(true)}>
        Watch Point History
      </Button>
      <br />
      <Button onClick={() => setIsOpenRedeemCoupon(true)}>Redeem Coupon</Button>
      <br />
      <Button onClick={() => setIsOpenRaking(true)}>Watch Raking</Button>
      <ExamPointHistory isOpen={isOpenHistory} onClose={onCloseHistory} />
      <ExamRanking isOpen={isOpenRaking} onClose={onCloseRaking} />
      <RedeemCoupon
        redeemPoint={2000}
        isOpen={isOpenRedeemCoupon}
        onClose={onCloseRedeemCoupon}
        onSubmit={() => { }}
      />
    </div>
  );
};

export default TestPage;
