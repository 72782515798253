import '../ExamInfoLayout/exam-info-layout.scss';

import * as React from 'react';

import { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Button from '@components/Button';
import { ExamContext } from '@contexts/ExamContext';
import { FormInfo } from 'exam/exam.model';
import LeftBackItem from '@components/LeftBackItem';
import StarScore from '@components/StarScore';
import { getFormInfo } from '@core/@services/exam.service';

interface LocationState {
  id: string;
}

export const FormInfoLayout: React.FC = ({ children }) => {
  const {
    setExamNo,
    setFormInfo,
    formInfo: { score, take_flag, form_content_type_id },
  } = useContext(ExamContext);
  const history = useHistory();
  const locationState = useLocation<LocationState>().state;

  useEffect(() => {
    if (locationState?.id) {
      getFormInfo(locationState.id).then((data: FormInfo) => {
        setFormInfo(data);
      });
    }
  }, [locationState?.id]);

  const onExam = () => {
    setExamNo(1);
    history.push('/form');
  };

  return (
    <div className="light-blue-bg fixed inset-0">
      <div className="header z-50">
        <div className="fixed w-full flex justify-end px-4 pt-16">
          <StarScore>{score}</StarScore>
        </div>
        <div className="absolute w-full px-4 pt-3">
          <LeftBackItem onClick={() => history.push('/menu-detail/3')} />
        </div>
      </div>
      <div className="md:box-content mt-8">{children}</div>
      {take_flag && form_content_type_id === 1 ? (
        <div className="exam-info-footer z-50 in-active fixed bottom-0"></div>
      ) : form_content_type_id === 1 ? (
        <div className="exam-info-footer z-50 active fixed bottom-0 flex items-end">
          <Button className="h-5/6 w-full" onClick={onExam}></Button>
        </div>
      ) : null}
    </div>
  );
};
