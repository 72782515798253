import React from 'react';

interface PropsType {
  title: string;
  required?: boolean;
}

const FormLabel: React.FC<PropsType> = ({ title, required }) => {
  return (
    <label className="block font-label-size-18">
      {title}
      {required && (
        <label className="font-red font-label-size-18 ml-0.5">*</label>
      )}
    </label>
  );
};

export default FormLabel;
