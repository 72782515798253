import React, { useEffect } from 'react';

import { Information } from '@components/Information';
import LeftBackItem from '@components/LeftBackItem';
import liff from '@line/liff';
import { useAppDispatch } from '@contexts/AppContext';
import { ImageProfile } from '@components/ImageProfile';
import { useHistory } from 'react-router-dom';

export const DocumentLayout: React.FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch({ type: 'BG_SOUND', payload: true });
  }, []);

  return (
    <div className="light-blue-bg flex">
      <div className="header z-20">
        <div className="absolute w-full px-4 pt-3">
          <LeftBackItem onClick={() => { history.goBack() }} />
          <Information />
        </div>
        <div className="absolute top-14 right-3">
          <div className="image-profile">
            <ImageProfile />
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};
