import axios from '@core/axios';
import { AxiosResponse } from 'axios';

const RegisterWithOTP = (data: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/Member/reqRegisterWithOTP`, data)
      .then((res: AxiosResponse<any>) => {
        if (res.status === 200) {
          return resolve(res.data);
        }
      })
      .catch((err: any) => reject(err.response));
  });
};

export default RegisterWithOTP;
