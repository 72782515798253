import { ExamContext } from '@contexts/ExamContext';
import { AnswerList, SubmitExamAnswerList } from 'exam/exam.model';
import React, { useContext, useEffect, useState } from 'react';
import './exam.scss';

const Exam = () => {
  const {
    examInfo: { question_list },
    examNo,
    answerList,
    setAnswerList,
    submitScore,
  } = useContext(ExamContext);
  const question_id = question_list[examNo - 1].question_id;
  const [value, setValue] = useState<string>();

  useEffect(() => {
    if (examNo) {
      setValue(
        answerList.find((ans) => ans.question_id === question_id)?.answer_id
      );
    }
  }, [examNo, answerList]);

  const onChangeAnswer = (answer_id, questionIdex) => {
    const newAnswer: SubmitExamAnswerList = {
      question_id,
      group_no: question_list[examNo - 1].group_no,
      answer_id,
    };
    answerList[questionIdex] = newAnswer;
    setAnswerList(answerList);
  };

  const answerRadioList = (answerRadio: AnswerList[]) => {
    return answerRadio.map((answer: AnswerList) => (
      <label className="inline-flex mt-2 w-full" key={answer.answer_id}>
        <input
          type="radio"
          className="form-radio exam-radio h-7 w-7"
          name={question_id}
          value={answer.answer_id}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          checked={value === answer.answer_id}
        />
        <span className="ml-2 text-2xl whitespace-pre-wrap">
          {answer.answer_name}
        </span>
      </label>
    ));
  };

  return (
    <div className="fixed top-40 px-8 w-full overflow-y-auto">
      <div className={`exam-border + ${submitScore ? 'complete' : ''}`}>
        <div
          className={`exam + ${
            submitScore ? 'complete' : ''
          } h-full text-2xl p-2`}
        >
          <p className="header-question text-3xl font-bold text-center whitespace-pre-wrap">
            {examNo}.{' '}
            {question_list && question_list[examNo - 1].question_title}
          </p>
          <div
            onChange={(e: any) => onChangeAnswer(e.target.value, examNo - 1)}
          >
            {question_list &&
              question_list[examNo - 1].answer_list &&
              answerRadioList(question_list[examNo - 1].answer_list || [])}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Exam;
