import Loading from '@components/Loading';
import { LiffService } from '@core/@services/Liff.service';
import { Token } from '@core/core.model';
import { env } from '@core/env';
import LoginUser from '@core/LoginUser';
import { LoginResponse } from '@login/login.model';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export interface LocationState {
  coupon_id: string;
  isInApp: boolean;
}

const Coupon = () => {
  const locationState = useLocation<LocationState>();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (locationState.search) {
      const liffId = env.pointLiffId || '';
      LiffService.connect(liffId, login);
    }
  }, [locationState.search]);

  const login = (profile: {
    userId: string;
    displayName: string;
    pictureUrl?: string;
  }) => {
    LoginUser({ line_id: profile.userId, otp_flag: true })
      .then((res: LoginResponse) => {
        const hillToken: Token = {
          access_token: res.token,
        };
        const campaignInfo = res.campaign_info ? res.campaign_info[0] : null;
        sessionStorage.setItem('hillToken', JSON.stringify(hillToken));
        sessionStorage.setItem('rankNo', `${res.rank_no}`);
        sessionStorage.setItem('campaignInfo', JSON.stringify(campaignInfo));
        sessionStorage.setItem('liff-profile', JSON.stringify(profile));
        sessionStorage.setItem(
          'otp_pass_flag',
          JSON.stringify(res.otp_pass_flag)
        );
        sessionStorage.setItem(
          'keep_data_flag',
          JSON.stringify(res.keep_data_flag)
        );
        sessionStorage.setItem(
          'transfer_data_flagprofile',
          JSON.stringify(res.transfer_data_flag)
        );
        setIsLoading(false);

        history.push('/coupon-detail', {
          coupon_id: new URLSearchParams(locationState.search).get('coupon_id'),
        });
      })
      .catch((error) => {
        console.log(error, 'Login error');
      });
  };
  return <>{isLoading && <Loading />}</>;
};

export default Coupon;
