import '../MemberInfoLayout/member-info-layout.scss';
import '../MemberInfoLayout/member-info-layout.scss';

import { useEffect, useState } from 'react';

import Button from '@components/Button';
import { ImageProfile } from '@components/ImageProfile';
import { Information } from '@components/Information';
import LeftBackItem from '@components/LeftBackItem';
import liff from '@line/liff';
import { useHistory } from 'react-router-dom';

export const MemberInfoLayout: React.FC = ({ children }) => {
  const [lineName, setLineName] = useState<string>('');
  const [isGiff, setIsGiff] = useState<boolean>(true);
  const history = useHistory();

  useEffect(() => {
    const { displayName } = JSON.parse(
      sessionStorage.getItem('liff-profile') || ''
    );
    setLineName(displayName);
    validateBtn(history.location.pathname);
  }, []);

  const onClickMenu = (path: string) => {
    history.push(path);
  };

  useEffect(() => {
    validateBtn(history.location.pathname);
  }, [history.location.pathname]);

  const validateBtn = (path: string) => {
    setIsGiff(path === '/member-info');
  };

  return (
    <div className="shiba fixed inset-0">
      <div className="header z-50">
        <div className="absolute w-full px-4 pt-3">
          <LeftBackItem onClick={() => history.push('menu')} />
          <Information />
        </div>
      </div>

      <div className="justify-center items-center flex  flex-col fixed inset-0 ">
        <div className="header-banner items-baseline">
          <div className="justify-center m-auto" style={{ width: '22%' }}>
            <ImageProfile />
          </div>
          <div className="text-center m-auto">
            <label className="m-auto display-name">{lineName}</label>
          </div>
        </div>
        <div className="relative info-bg flex flex-col outline-none focus:outline-none">
          <div className="flex flex-col pt-12" style={{ flex: '1' }}>
            {children}
          </div>
        </div>
        <div
          className="flex mt-5 place-content-between"
          style={{
            maxWidth: ' 337px',
            width: '100%',
          }}
        >
          <Button
            className="btn-lesson btn-size"
            onClick={() => onClickMenu('menu')}
          />
          {isGiff ? (
            <Button
              className="btn-redeem btn-size"
              onClick={() => onClickMenu('coupon')}
            />
          ) : (
            <Button
              className="btn-profile btn-size"
              onClick={() => onClickMenu('member-info')}
            />
          )}
        </div>
      </div>
    </div>
  );
};
