import './exam-info-layout.scss';

import * as React from 'react';

import { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Button from '@components/Button';
import { ExamContext } from '@contexts/ExamContext';
import { ExamInfo } from 'exam/exam.model';
import LeftBackItem from '@components/LeftBackItem';
import StarScore from '@components/StarScore';
import { getDateTimeServer } from '@core/@services/date-time.service';
import { getExamInfo } from '@core/@services/exam.service';

interface LocationState {
  id: string;
  menuId: string;
}

export const ExamInfoLayout: React.FC = ({ children }) => {
  const {
    examInfo: { exam_point, take_flag },
    setExamInfo,
    setStartDateTime,
    setExamNo,
  } = useContext(ExamContext);
  const history = useHistory();
  const locationState = useLocation<LocationState>().state;
  const [isBack, setIsBack] = React.useState(false);
  const [isInfoGraphic, setIsInfoGraphic] = React.useState(false);

  useEffect(() => {
    if (locationState?.id) {
      getExamInfo(locationState.id).then((data: ExamInfo) => {
        setExamInfo(data);
      });
    }
  }, [locationState?.id]);

  useEffect(() => {
    setIsBack(history.location.pathname.includes('see-more'));
    setIsInfoGraphic(history.location.pathname.includes('graphic'));
  }, [history.location.pathname]);

  const onExam = () => {
    getDateTimeServer().then((data: any) => {
      setStartDateTime(data.datetime);
      setExamNo(1);
      history.push('/exam');
    });
  };

  const infoAction = () => {
    return take_flag ? (
      <div className="exam-info-footer z-50 in-active fixed bottom-0"></div>
    ) : (
      <div className="exam-info-footer z-50 active fixed bottom-0 flex items-end">
        <Button className="h-5/6 w-full" onClick={onExam}></Button>
      </div>
    );
  };

  const onSeeMore = () => {
    history.push('/exam-info/see-more');
  };

  const infoGraphicAction = () => {
    return take_flag ? (
      <div className="info-footer h-1/6 z-50 in-active fixed bottom-0">
        <Button className="h-full w-3/6" onClick={onSeeMore}></Button>
      </div>
    ) : (
      <div className="info-footer h-1/6 z-50 active fixed bottom-0 flex items-end">
        <Button className="h-full w-3/6" onClick={onSeeMore}></Button>
        <Button className="h-full w-3/6" onClick={onExam}></Button>
      </div>
    );
  };

  return (
    <div className="light-blue-bg fixed inset-0">
      <div className="header z-50">
        <div className="fixed w-full flex justify-end px-4 pt-16">
          <StarScore>{exam_point}</StarScore>
        </div>
        <div className="absolute w-full px-4 pt-3">
          {isBack ? (
            <LeftBackItem />
          ) : (
            <LeftBackItem
              onClick={() =>
                history.push(`/menu-detail/${locationState?.menuId}`)
              }
            />
          )}
        </div>
      </div>
      <div className="md:box-content mt-8">{children}</div>
      {isInfoGraphic ? infoGraphicAction() : infoAction()}
    </div>
  );
};
