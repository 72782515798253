import React from 'react';
import CSS from 'csstype';
import Star from 'assets/layout/exam-info/star.svg';

const starContent: CSS.Properties = {
  backgroundColor: '#d6182f',
  borderRadius: '15px',
};

const starLayout: CSS.Properties = {
  background: 'url('+Star+') no-repeat right',
  width: '35px',
};

type StarScoreProps = {
  children: React.ReactNode;
  color?: string;
  fontColor?: string
};

const StarScore = ({ children, color, fontColor }: StarScoreProps) => {
  return (
    <div className='flex w-20 h-8 pr-1' style={color ? { ...starContent, backgroundColor: color } : starContent}>
      <div style={starLayout}></div>
      <div
        className='flex justify-center w-5/6 text-2xl text-white font-bold'
        style={fontColor ? { color: fontColor } : {}}
      >
        {children}
      </div>
    </div>
  );
};
export default StarScore;
