import * as React from 'react';

import { useHistory } from 'react-router-dom';

export const Information: React.FC = () => {
  const history = useHistory();
  const goToInformation = () => {
    history.push(`/information`);
  };

  return <div className="info" onClick={goToInformation}></div>;
};
