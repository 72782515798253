import { ExamContext } from '@contexts/ExamContext';
import React, { useContext } from 'react';
import './info-video.scss';
import { InfoVideo } from './InfoVideo';

export const FormInfoVideo: React.FC = () => {
  const {
    formInfo: { form_title, form_video, form_desc, take_flag, form_id },
  } = useContext(ExamContext);

  return (
    <InfoVideo
      title={form_title || ''}
      desc={form_desc || ''}
      video={form_video || ''}
      isForm={true}
      isTakeFlag={take_flag}
      formId={form_id}
    />
  );
};
