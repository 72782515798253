import Axios from 'axios';
import FormLabel from '@components/FormLabel';
import Input from '@components/Input/Input';
import GetProvince from 'member/api/GetProvinces';
import { Province } from 'member/member.model';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from '@components/Button';
import Select from '@components/Select';

interface PropType {
  onSave: (value: any) => void;
}

const VeterinaryForm: React.FC<PropType> = (props) => {
  const { onSave } = props;

  const form = useForm();

  const [provinces, setProvinces] = useState<Province[]>([]);

  useEffect(() => {
    Axios.all([GetProvince()])
      .then(
        Axios.spread((...res: any) => {
          setProvinces(res[0]?.data ? res[0].data.province_list : []);
        })
      )
      .catch((errors: any) => {
        console.log('Error get option value' + errors);
      });
  }, []);

  const onSubmit = (data: any) => {
    onSave({ ...data, member_type_id: 2 });
  };

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <div className="w-full">
        <div className="mb-4 ml-8 mr-6">
          <FormLabel title="เลขใบประกอบวิชาชีพ" required></FormLabel>
          <Input
            id="vet_license_id"
            type="text"
            {...form.register('vet_license_id', { required: true })}
          />
        </div>
        <div className="mb-4 ml-8 mr-6">
          <FormLabel title="จังหวัดที่ทำงาน" required></FormLabel>
          <Select
            type="text"
            {...form.register('province_name', { required: true })}
          >
            {provinces.map((option) => {
              return (
                <option key={option.name} value={option.name}>
                  {option.name}
                </option>
              );
            })}
          </Select>
        </div>
      </div>
      <div className="register-type-form-btn text-center mb-4 mt-10">
        <Button
          className="btn-register hover:bg-blue-600 mb-3 tracking-wider"
          type="submit"
        >
          ยืนยัน
        </Button>
      </div>
    </form>
  );
};

export default VeterinaryForm;
