import axios from '@core/axios';
import { LoginResponse } from '@login/login.model';
import { AxiosResponse } from 'axios';
import { LoginRequest } from './core.model';

const LoginUser = (data: LoginRequest): Promise<LoginResponse> => {
  return new Promise((resolve, reject) => {
    axios
      .post<LoginResponse>(`/Member/reqLogin`, data)
      .then((res: AxiosResponse<LoginResponse>) => {
        if (res.status === 200) {
          return resolve(res.data);
        }
      })
      .catch((err: any) => reject(err?.response));
  });
};

export default LoginUser;
