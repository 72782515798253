import * as React from 'react';
import LeftBackItem from '@components/LeftBackItem';
import { LocationState } from 'coupon/Coupou';
import { useHistory, useLocation } from 'react-router-dom';
import liff from '@line/liff';

export const CouponLayout: React.FC = ({ children }) => {
  const locationState = useLocation<LocationState>();
  const history = useHistory();

  const onClickBack = () => {
    if(!locationState?.state?.isInApp) {
      liff.closeWindow()
    } else {
      history.goBack()
    }
  }

  return (
    <div className="light-blue-bg fixed inset-0">
      <div className="header z-50">
        <div className="absolute w-full px-4 pt-3">
          <LeftBackItem
            onClick={() => onClickBack()}
          />
        </div>
      </div>
      <div className="md:box-content mt-8">{children}</div>
    </div>
  );
};
