import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import '../exam/ranking/ranking.scss';
import { Avatar } from './Avatar';
import tag from '../assets/layout/tag.svg';

interface Profile {
  pictureUrl: string;
  rankNo: string;
}

export const ImageProfile: React.FC = () => {
  const [profile, setProfile] = useState<Profile>({
    pictureUrl: '',
    rankNo: '',
  });

  const history = useHistory();

  useEffect(() => {
    const { pictureUrl } = JSON.parse(
      sessionStorage.getItem('liff-profile') || '{}'
    );
    const rankNo = JSON.parse(sessionStorage.getItem('rankNo') || '{}');
    setProfile({ pictureUrl, rankNo });
  }, []);

  const onClick = () => {
    history.push('/member-info');
  };

  return (
    <div
      className="flex flex-col items-center justify-center"
      onClick={onClick}
    >
      {profile.pictureUrl ? (
        <img
          alt="line-profile"
          style={{
            borderRadius: '50%',
          }}
          src={profile.pictureUrl}
        />
      ) : (
        <Avatar background="#FFFFFF" color="#0255A333" />
      )}
      {+profile.rankNo > 0 && (
        <div className="point-bage-wrapper flex justify-center">
          <img src={tag} alt="tag" className="tag pb-1" />
          <div className="tag-text">{profile.rankNo}</div>
        </div>
      )}
    </div>
  );
};
