import ExamButton, { ExamButtonType } from '@components/ExamButton';
import ExamGotPoint from '@components/ExamGotPoint';
import { ExamContext } from '@contexts/ExamContext';
import { submitExam } from '@core/@services/exam.service';
import { SubmitExam, SubmitExamResponse } from 'exam/exam.model';
import React, { useContext, useEffect, useState } from 'react';

const PointSubmitComplete = (props: { isOpen?: boolean }) => {
  const { isOpen } = props;
  const {
    examInfo: { exam_id  },
    answerList,
    startDateTime,
    endDateTime,
  } = useContext(ExamContext);
  const [point, setPoint] = useState<number>(0);

  useEffect(() => {
    if (isOpen) {
      const request: SubmitExam = {
        submit_flag: true,
        exam_id,
        start_datetime: startDateTime,
        end_datetime: endDateTime,
        answer_list: answerList,
      };
      submitExam(request).then((data: SubmitExamResponse) => {
        setPoint(data.receive_point);
      });
    }
  }, [isOpen])

  return (
    <>
      {isOpen ? (
        <div
          className='modal-container flex flex-col justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none fade'
        >
          <ExamGotPoint point={point}>
            คุณได้คะแนนจากบทเรียนนี้แล้ว
          </ExamGotPoint>
          <div className='flex'>
            <ExamButton type={ExamButtonType.ToClass}>
              กลับสู่ห้องเรียน
            </ExamButton>
            <ExamButton type={ExamButtonType.ToRanking}>
              ดูลำดับของฉัน
            </ExamButton>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default PointSubmitComplete;
