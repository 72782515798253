import '../coupon/coupon.scss';

import { useEffect, useState } from 'react';

import Button from '@components/Button';
import { Coupon } from './coupon.model';
import GetCouponList from './api/GetCouponList';
import RedeemCoupon from './redeem-coupon/RedeemCoupon';
import RedeemCouponPoint, {
  RedeemCouponPointResponse,
} from './api/RedeemCouponPoint';
import StarIcon from '../assets/layout/exam-info/star-1.svg';
import { useHistory } from 'react-router-dom';
import FailModal from '@components/FailModal';

const CoupouList = () => {
  const [couponList, setCouponList] = useState<Coupon[]>([]);
  const [point, setPoint] = useState<number>(0);
  const history = useHistory();
  const [isOpenRedeemCoupon, setIsOpenRedeemCoupon] = useState(false);
  const [pointRedeem, setPointRedeem] = useState<number>(0);
  const [couponId, setCouponId] = useState<string>('');

  useEffect(() => {
    getCoupon();
  }, []);

  const getCoupon = () => {
    GetCouponList()
      .then((res: any) => {
        setPoint(res.data.point);
        setCouponList(res.data.coupon_list);
      })
      .catch((error) => {
        console.log(error, 'Get member info failer');
      });
  };

  const onRedeemCoupon = (couponPoint: number, couponId: string) => {
    setIsOpenRedeemCoupon(true);
    setPointRedeem(couponPoint);
    setCouponId(couponId);
  };

  const onClickDetail = (coupon_id: string) => {
    history.push('/coupon-detail', { coupon_id, isInApp: true });
  };

  const onCloseRedeemCoupon = () => {
    setIsOpenRedeemCoupon(false);
  };

  const onSubmmit = (coupon_id: string) => {
    RedeemCouponPoint({ coupon_id })
      .then(({ result_code }: RedeemCouponPointResponse) => {
        const isLackOfPoint = result_code === '11';
        if (isLackOfPoint) {
          setPointRedeem(-1);
          return;
        }
        getCoupon();
        onCloseRedeemCoupon();
      })
      .catch((error) => {
        console.log(error, 'Coupon error');
        setPointRedeem(-1);
        return;
        // onCloseRedeemCoupon();
      });
  };

  return (
    <>
      <div className="grid grid-cols-2 gap-4">
        <div className="flex ml-7">
          <label className="label-header-coupon">ของรางวัล</label>
        </div>
        <div className="flex justify-end items-center w-5/6">
          <img className="h-5" src={StarIcon} alt="star-point"></img>
          <label className="label-coupon label-header-point ml-3">
            {point}
          </label>
        </div>
      </div>
      <div
        className="grid gap-y-4 m-auto mt-2 mb-6 w-9/12 overflow-y-auto"
        style={{ maxHeight: '380px' }}
      >
        {couponList.map((c: Coupon, index: number) => {
          return (
            <div
              className={`${
                c.coupon_status > 1 ? 'bg-color-blue' : 'bg-white'
              } grid grid-cols-2 bg-white`}
              key={c.coupon_id}
              style={{
                height: '125px',
              }}
            >
              <div>
                <img
                  src={c.coupon_image}
                  className={`w-full h-full`}
                  alt="img-coupon"
                />
              </div>
              <div className="justify-items-center m-auto">
                {c.coupon_status === 0 && (
                  <div>
                    <div className="grid gap-y-2">
                      <label className="label-size-16 ml-2 font-grey text-center">
                        คูปองมีผู้ใช้สิทธิ์
                        <br />
                        ครบแล้ว
                      </label>
                    </div>
                  </div>
                )}
                {c.coupon_status === 1 && (
                  <div>
                    <div className="grid gap-y-2">
                      <label className="label-size-16 font-heavy-blue text-center">
                        หมดอายุ
                        <br />
                        {c.coupon_exp_date}
                      </label>

                      <div>
                        <Button
                          className="label-coupon border-solid border rounded-3xl w-32 mt-2 bg-color-blue"
                          onClick={() => onClickDetail(c.coupon_id)}
                        >
                          ดูรายละเอียด
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
                {(c.coupon_status === 2 || c.coupon_status === 3) && (
                  <div className="grid">
                    <div className="flex justify-center items-center h-12">
                      <img className="h-7" src={StarIcon} alt="star-point" />
                      <label className="label-coupon point pl-2 pt-1">
                        {c.coupon_point}
                      </label>
                    </div>
                    <div>
                      <Button
                        className="label-coupon label-coupon-button  border-solid border rounded-3xl w-28 mt-2"
                        onClick={() =>
                          onRedeemCoupon(c.coupon_point, c.coupon_id)
                        }
                      >
                        แลกคูปอง
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
        {pointRedeem !== -1 && (
          <RedeemCoupon
            redeemPoint={pointRedeem}
            isOpen={isOpenRedeemCoupon}
            onClose={onCloseRedeemCoupon}
            onSubmit={onSubmmit}
            id={couponId}
          />
        )}
        <FailModal
          isOpen={pointRedeem === -1 && isOpenRedeemCoupon}
          message={'คุณมีคะแนนไม่เพียงพอเพื่อแลกคูปอง'}
          onClose={onCloseRedeemCoupon}
        />
      </div>
    </>
  );
};

export default CoupouList;
